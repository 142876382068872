import React from 'react';
import Button from '@mui/material/Button';
import styles from "./toolTip.module.scss";

export default function ToolTip(props) {

	const getClasses = () => {
		let classes: string[] = [];
		props.toolDetails.classes.forEach((cls) => {
			classes.push(styles[cls])
		});
		return classes;
	}

	return (
		<div className={getClasses().join(' ')}>
			{props.children}
			<div className={styles.buttonwrap}>
				{props.toolDetails.stepOn < props.toolDetails.length ?
					<>
						<Button className={styles.tourbutton} onClick={() => props.toolDetails.closeTour()}>Skip</Button>
						<Button className={styles.tourbuttonPrimary} color="primary" onClick={() => props.toolDetails.goTo()}>Next</Button>
						</>
					: <Button className={styles.tourbuttonPrimary} color="primary" onClick={() => props.toolDetails.closeTour()}>Close</Button>
				}
			</div>
		</div>
    );
}