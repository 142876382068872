import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import registerServiceWorker from './registerServiceWorker';
import {
	ThemeProvider,
	unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@mui/material/styles";
import { maplesprimary, maplesaccent } from '../src/assets/theme/maples-material-ui-theme-base-colors';
import 'msg-react-lib/dist/index.css';
import './index.scss';
import { ErrorBoundary } from './components/shared/errorBoundary/errorBoundary';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string | undefined;
const theme = createMuiTheme({
	palette: {
		primary: {
			main: maplesprimary[500]
		},
		secondary: {
			main: maplesaccent[500]
		},
	}
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<ErrorBoundary>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</ErrorBoundary>
);

registerServiceWorker();