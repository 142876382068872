import React, { Fragment } from 'react';
import styles from './status.module.scss';
import { Formik, Form, Field } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

export default function BeneficialOwnerStatus(props) {
	let state = { boStatus: props.boStatus ? 'true' : props.boStatus === null ? '' : 'false' }

	async function handleSubmit(values) {
		state = values;
		await props.setBoStatus(values.boStatus === 'true' ? true : values.boStatus === '' ? null : false)
	}

	return (
		<div className="stepContent">

			<Formik enableReinitialize initialValues={state} onSubmit={handleSubmit}>
				{
					(formikProps) => {
						props.bindSubmitFormHandler!(formikProps.submitForm);
						props.setFormikProps!(formikProps);
						return (
							<>
								<h1>Beneficial Owner Declaration</h1>
								<div className={`stepLeft`}>
									<div className={`overflowText`}>
										<h2>Do any beneficial owners own 10% or more interests?</h2>
										<article>To determine whether a Beneficial Owner is present, please read the below definition:
                      <ol>
												<li>
													A Beneficial Owner is a natural person who ultimately owns or controls, whether through direct or indirect ownership or control, 10% or more of the voting or economic interests in the entity, or who otherwise exercises ultimate effective control over the management of the entity; or
                        </li>
												<li>
													in relation to a trust or other legal arrangement, a natural person who exercises ultimate effective control over the Entity.
                        </li>
											</ol>
										</article>
									</div>
								</div>
								<Form className={'stepRight stepDetails'}>
									<FormControlLabel
										style={{ margin: '0' }}
										value="boStatus"
										label=""
										labelPlacement="start"
										control={
											<Field name="boStatus">
												{({ field }) => (
													<RadioGroup name="boStatus" value={field} className={`rowWrap ${styles.rowWrap}`} {...field}>
														<FormControlLabel
															className={`${styles.radioSection} ${state.boStatus === 'true' ? styles.active : ''}`}
															control={<Radio
																autoFocus={state.boStatus === 'true' || state.boStatus === ''}
																color="primary"
																value='true'
															/>}
															label={
																<Fragment>
																	<div>Yes</div>
																</Fragment>
															}
														/>
														<FormControlLabel
															className={`${styles.radioSection}  ${state.boStatus === 'false' ? styles.active : ''}`}
															control={<Radio
																autoFocus={state.boStatus === 'false'}
																color="primary"
																value='false'
															/>}
															label={
																<Fragment>
																	<div>No</div>
																</Fragment>
															}
														/>
													</RadioGroup>
												)}
											</Field>
										} />
								</Form>
							</>
						)
					}
				}
			</Formik>
		</div>
	);
}