import React from "react";
import { createBrowserRouter, Outlet, useNavigate, useOutletContext } from "react-router-dom";
import logo from "./assets/Logo.svg";
import { Layout } from './components/layout';
import { Home } from './components/home';
import { Celebration } from './components/shared/celebration/celebration';
import { SummaryIssues } from './components/investors/summaryIssues';
import { InvestorProfile } from './components/investors/individual/profile';
import { DataProtection } from './components/investors/dataProtection';
import { InvestorAddress } from './components/investors/investorAddress';
import { DueDiligenceConsent } from './components/investors/dueDiligenceConsent';
import { EnhancedDueDiligence } from './components/investors/enhancedDueDiligence';
import { CitizenshipStatus } from './components/investors/individual/citizenshipStatus';
import { TaxResidencies } from './components/investors/individual/taxResidencies';
import { EntityProfile } from './components/investors/entity/profile';
import { ErrorBoundary } from './components/shared/errorBoundary/errorBoundary';
import { Unauthorized } from './components/shared/misc/unauthorized';
import { NotFound } from './components/shared/misc/notFound';
import EntityChart from './components/entityChart/entityChart';
import SignIn from './components/signIn';
import CompleteSignup from './components/completeSignup';
import ForgotPasswordInitiate from './components/forgotPassword/forgotPasswordInitiate';
import ForgotPasswordVerify from './components/forgotPassword/forgotPasswordVerify';
import ForgotPasswordReset from './components/forgotPassword/forgotPasswordReset';



const logoDetails = {
	file: logo,
	altText: "KYC Investor Portal",
	width: 21,
};

const navigationDetails = [
	{
		path: "/",
		label: "Home",
	},
	{
		path: "/investorProfile",
		label: "InvestorProfile",
	},
	{
		path: "/dataProtection",
		label: "DataProtection",
	},
	{
		path: "/investorAddress",
		label: "InvestorAddress",
	},
	{
		path: "/dueDiligenceConsent",
		label: "DueDiligenceConsent",
	},
	{
		path: "/enhancedDueDiligence",
		label: "EnhancedDueDiligence",
	},
	{
		path: "/citizenshipStatus",
		label: "CitizenshipStatus",
	},
	{
		path: "/taxResidencies",
		label: "TaxResidencies",
	},
	{
		path: "/entityProfile",
		label: "EntityProfile",
	},
	{
		path: "/finished",
		label: "Celebration",
	},
	{
		path: "/summaryIssues",
		label: "SummaryIssues",
	},
	{
		path: "/unauthorized",
		label: "Unauthorized",
	},
  {
		path: "/notFound",
		label: "NotFound",
	},
  {
		path: "/entityChart",
		label: "EntityChart",
	},
  {
		path: "/signin",
		label: "SignIn",
	},
  {
		path: "/completeSignup",
		label: "Complete Signup",
	},
	{
		path: "/forgotPasswordInitiate",
		label: "Complete Signup",
	},
	{
		path: "/forgotPasswordVerify",
		label: "Complete Signup",
	},
	{
		path: "/forgotPasswordReset",
		label: "Complete Signup",
	},
];


let router;
const ElementWrapper = (props) => {
	const navigate = useNavigate();
	const outletContext = useOutletContext();
	const Element = props.routeElement;
	return <Element  navigate={navigate} outletContext={outletContext} {...props} />;
};

const ErrorBoundaryWithNavigate = (props) => {
	const navigate = useNavigate();
	return <ErrorBoundary navigate={navigate} {...props} />;
};

export const buildRouter = () => {
	router = createBrowserRouter([
		{
			path: "/",
			element: <LayoutComponent />,
			children: [
				{
					index: true,
					element: <ElementWrapper routeElement={Home} />
				},
				{
					path: "unauthorized",
					element: <ElementWrapper routeElement={Unauthorized} />
				},
				{
					path: "notFound",
					element: <ElementWrapper routeElement={NotFound} />
				},
				{
					path: "signin",
					element: <SignIn />,
				},
				{
					path: "completeSignup",
					element: <CompleteSignup />,
				},
				{
					path: "investorProfile",
					element: <ElementWrapper routeElement={InvestorProfile} />
				},
				{
					path: "dataProtection",
					element: <ElementWrapper routeElement={DataProtection} />
				},
				{
					path: "investorAddress",
					element: <ElementWrapper routeElement={InvestorAddress} />
				},
				{
					path: "dueDiligenceConsent",
					element: <ElementWrapper routeElement={DueDiligenceConsent} />
				},
				{
					path: "enhancedDueDiligence",
					element: <ElementWrapper routeElement={EnhancedDueDiligence} />
				},
				{
					path: "citizenshipStatus",
					element: <ElementWrapper routeElement={CitizenshipStatus} />
				},
				{
					path: "taxResidencies",
					element: <ElementWrapper routeElement={TaxResidencies} />
				},
				{
					path: "entityProfile",
					element: <ElementWrapper routeElement={EntityProfile} />
				},
				{
					path: "finished",
					element: <ElementWrapper routeElement={Celebration} />
				},
				{
					path: "entityChart",
					element: <ElementWrapper routeElement={EntityChart} />
				},
				{
					path: "/forgotPasswordInitiate",
					element: <ElementWrapper routeElement={ForgotPasswordInitiate} />
				},
				{
					path: "/forgotPasswordVerify",
					element: <ElementWrapper routeElement={ForgotPasswordVerify} />
				},
				{
					path: "/forgotPasswordReset",
					element: <ElementWrapper routeElement={ForgotPasswordReset} />
				}
			],
		},
	]);
	return router;
};

export const getRouter = () => {
	return router;
};

const LayoutComponent = () => {
	return (
		<ErrorBoundaryWithNavigate>
			<Layout logo={logoDetails} navigation={navigationDetails}>
				<div className="switchWrapper">
					<div>
						<Outlet />
					</div>
				</div>
			</Layout>
		</ErrorBoundaryWithNavigate>
	);
};
