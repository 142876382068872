import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { InvestorService } from '../services/investorService';

export function SubmitCase() {
    const navigate = useNavigate();

    return (
        <Button variant="contained" color="primary" onClick={async () => {
            await InvestorService.submitInvestor()
            navigate('/finished');
        }} disableElevation>Submit package for review</Button>
    );
};