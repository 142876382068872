import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function MessageGuide(props) {
	return (
			<Dialog
				open={props.isOpen}
				onClose={props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{props.title ?
					<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle> : null
				}
				<DialogContent>
					{props.children}
				</DialogContent>
				<DialogActions>
				<button className="iconButton" onClick={props.handleClose} color="primary">{(props.closeText) ? props.closeText : "CLOSE"}</button>
				</DialogActions>
			</Dialog>
		);
}