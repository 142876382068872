import React, { Component } from 'react';
import * as Shared from './shared/sharedTypes'
import { CountryService } from './services/countyService'
import { ValidationService } from './services/validationService'
import { InvestorService } from './services/investorService';
import { HighRiskJurisdictionsService } from './services/highRiskJurisdictionsService';
import EntityChart from './entityChart/entityChart';
import DialogStepper from './shared/dialogSteppers/individualDialogStepper';
import styles from './home.module.scss';
import PubSub from 'pubsub-js';
import NotificationPanel from './shared/notifications/notificationPanel'
import animation from '../assets/welcomeanimation.json';
import Lottie from 'lottie-react'
import Button from '@mui/material/Button';

const emptyValidations = {
  totalIssues: 0,
  notifications: [],
  controllingPersons: [],
  beneficialOwners: []
}

class State {
  countries: Shared.ICountry[] = [];
  highRiskJurisdictions: Shared.ICountry[] = [];
  investor: Shared.IInvestor = Shared.EmptyInvestor;
  controllingPerson: Shared.IControllingPerson = Shared.EmptyControllingPerson;
  secondLevelControllingPerson: Shared.ISecondLevelControllingPerson = Shared.EmptySecondLevelControllingPerson;
  stepperOn: boolean = false;
  unmounting: boolean = false;
  notificationsCollpased: boolean = true;
  showMainWorkflow: boolean = false;
  beneficialOwner: Shared.IBeneficialOwner = Shared.EmptyBeneficialOwner;
  validations: any = emptyValidations;
  isLoading: boolean = true; // New state variable
}

export class Home extends Component {
  static displayName = Home.name;
  state = new State();
  child: any;
  constructor(props) {
    super(props)
    this.populateCountries();
    this.populateHightRiskJuridictions();
    this.populateInvestor();
    this.populateValidations();
    this.updateInvestor = this.updateInvestor.bind(this);
    this.updateEntityInvestor = this.updateEntityInvestor.bind(this);
    this.updateControllingPerson = this.updateControllingPerson.bind(this);
    this.updateSecondLevelControllingPerson = this.updateSecondLevelControllingPerson.bind(this);
    this.submitInvestor = this.submitInvestor.bind(this);
    this.showStepper = this.showStepper.bind(this);
    this.setStep = this.setStep.bind(this);
    this.child = React.createRef();
    this.showNotifications = this.showNotifications.bind(this);
    this.toggleNotificationsOpen = this.toggleNotificationsOpen.bind(this);
    this.bindSubmitCurrentStepHandler = this.bindSubmitCurrentStepHandler.bind(this);
    this.updateBeneficialOwner = this.updateBeneficialOwner.bind(this);
    this.handleEntityDialogClose = this.handleEntityDialogClose.bind(this);
    this.populateValidations = this.populateValidations.bind(this);
  }

  async componentDidMount() {
    await Promise.all([
      this.populateCountries(),
      this.populateHightRiskJuridictions(),
      this.populateInvestor(),
      this.populateValidations()
    ]);
    PubSub.subscribe('SHOW_NOTIFICATIONS', this.showNotifications);
    this.setState({ isLoading: false });
  }

  showNotifications(type, content) {
    this.setState({
      validations: content
    })
  }

  private async populateCountries() {
    await CountryService.getCountriesList()
      .then(result => { this.setState({ countries: result }) })
  }

  private async populateInvestor() {
    await InvestorService.getInvestor()
      .then(result => {
        result.highRiskInvestor = this.highRiskInvestor(result);
        this.setState({ investor: result });
        this.setState({ investorType: result.investorType });
        this.setState({ showMainWorkflow: result.mainWorkflowCompleted !== true });
      })
  }

  private async populateValidations() {
    await ValidationService.getValidations()
      .then(result => {
        this.setState({ validations: result });
      })
  }

  private async submitInvestor() {
    await InvestorService.submitInvestor()
  }

  private async populateHightRiskJuridictions() {
    await HighRiskJurisdictionsService.getHighRiskJurisdictions()
      .then(result => {
        this.setState({ highRiskJurisdictions: result });
      });
  }

  private async updateInvestor(investor: Shared.IInvestor) {
    investor.highRiskInvestor = this.highRiskInvestor(investor);
    await this.setState({ investor: investor })
  }

  private async updateEntityInvestor() {
    await ValidationService.validateEntitySubmit().then(async result => {
      await InvestorService.getInvestor()
        .then(async result => {
          result.highRiskInvestor = this.highRiskInvestor(result);
          await this.setState({ investor: result })
        })
    })
  }

  private async updateControllingPerson(controllingPerson: Shared.IControllingPerson) {
    await this.setState({ controllingPerson: controllingPerson })
  }

  private async updateBeneficialOwner(beneficialOwner: Shared.IBeneficialOwner) {
    await this.setState({ beneficialOwner: beneficialOwner })
  }

  private async updateSecondLevelControllingPerson(secondLevelControllingPerson: Shared.IControllingPerson) {
    await this.setState({ secondLevelControllingPerson: secondLevelControllingPerson });
  }

  private async handleEntityDialogClose() {
    await this.populateInvestor();
  }

  private highRiskInvestor(investor): boolean {
    var highRisk: boolean = false
    if (investor.isPep || investor.riskRating === 'High') {
      highRisk = true;
    } else {
      highRisk = this.state.highRiskJurisdictions.find(x => [investor.nationality, investor.birthCountry, investor.businessPrincipalPlace, investor.registrationCountry].includes(x.code)) !== undefined
      if (!highRisk && investor.investorAddress != null && investor.investorAddress.length > 0) {
        investor.investorAddress.forEach(address => highRisk = this.state.highRiskJurisdictions.find(x => x.code === address.countryCode) !== undefined);
      }
    }
    return highRisk;
  }

  showStepper() {
    this.setState({
      unmounting: true
    }, () => {
      setTimeout(() => {
        this.setState({
          stepperOn: !this.state.stepperOn
        })
      }, 600);
    });
  }

  //Set Step In Dialog
  setStep(step) {
    this.child.current.setStep(step);
  }

  lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animation
  }

  toggleNotificationsOpen(openState) {
    this.setState({
      notificationsCollpased: openState !== undefined ? openState : !this.state.notificationsCollpased
    })
  }

  private submitCurrentStep = () => {
  };

  bindSubmitCurrentStepHandler(submitCurrentStepHandler) {
    this.submitCurrentStep = submitCurrentStepHandler;
  }

  render() {
    return (
      <>
        {
          !this.state.stepperOn ?
            <div className={`${styles.homeLayout} ${this.state.unmounting ? styles.unmounting : ''}`}>
              <div className={styles.intro}>
                <h1>Hello <span title={this.state.investor.name}>{this.state.investor.name}</span></h1>
                <div className={styles.content}>
                  <p>Welcome to the <span>Maples Investor Portal</span></p>
                  <p>Here we will take you through the steps to complete the 'Know Your Customer' process.</p>
                  <p>Once you have completed the following steps, your information will be reviewed by Maples staff. If any additional information is required, we will be in contact.</p>
                  <p>When you are ready, click on the button and 'Let's get started'.</p>
                </div>
                <Button variant="contained" color="primary" disabled={this.state.isLoading} className={styles.openButton} onClick={() => { this.showStepper() }} disableElevation>Let’s get started <span className={styles.arrowIcon}></span></Button>
              </div>
              <div>
                <Lottie {...this.lottieOptions} />
              </div>
            </div>
            :
            <>
              <NotificationPanel
                investor={this.state.investor}
                togglePanel={this.toggleNotificationsOpen}
                isCollapsed={this.state.notificationsCollpased}
                validations={this.state.validations}
                setStep={this.setStep}
                submitCurrentStep={this.submitCurrentStep}
                type={this.state.investor.typeDiscriminator}
              />
              <div className={styles.stepperContainer}>
                {
                  this.state.investor.typeDiscriminator === "Entity" ?
                    <EntityChart
                      ref={this.child}
                      investor={this.state.investor}
                      updateControllingPerson={this.updateControllingPerson}
                      updateEntityInvestor={this.updateEntityInvestor}
                      controllingPerson={this.state.controllingPerson}
                      updateBeneficialOwner={this.updateBeneficialOwner}
                      beneficialOwner={this.state.beneficialOwner}
                      countries={this.state.countries}
                      updateSecondLevelControllingPerson={this.updateSecondLevelControllingPerson}
                      secondLevelControllingPerson={this.state.secondLevelControllingPerson}
                      validations={this.state.validations}
                      submitInvestor={this.submitInvestor}
                      showMainWorkflow={this.state.showMainWorkflow}
                      populateValidations={this.populateValidations}
                      toggleNotificationsPanel={this.toggleNotificationsOpen}
                    />
                    :
                    <DialogStepper
                      ref={this.child}
                      description="This Dialog is for web wizards"
                      cancelText="Cancel"
                      confirmText="Save"
                      countries={this.state.countries}
                      highRiskJurisdictions={this.state.highRiskJurisdictions}
                      investor={this.state.investor}
                      handleClose={this.showStepper}
                      updateInvestor={this.updateInvestor}
                      submitInvestor={this.submitInvestor}
                      validations={this.state.validations}
                      toggleNotificationsPanel={this.toggleNotificationsOpen}
                      bindSubmitCurrentStepHandler={this.bindSubmitCurrentStepHandler}
                    />
                }
              </div>
            </>
        }
      </>
    );
  }
}