import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function SubmitChart(props) {
	return (
			<Dialog
				open={props.isOpen}
				onClose={props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{props.title ?
					<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle> : null
				}
				<DialogContent>
					{props.children}
				</DialogContent>
				<DialogActions>
				<Button onClick={props.handleConfirm} variant="contained" color="primary" disableElevation>{(props.confirmText) ? props.confirmText : "SUBMIT ANYWAY"}</Button>
				<Button onClick={props.handleClose} variant="contained" color="info" disableElevation>{(props.cancelText) ? props.cancelText : "FIX ERRORS"}</Button>
				</DialogActions>
			</Dialog>
		);
}