import React, { forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import styles from './dialogStepper.module.scss';
import ArrowButton from '../animatedComponents/arrowButton/arrowButton';
import BeneficialOwnerStatus from '../../investors/entity/beneficialOwner/status'
import Dialog from '@mui/material/Dialog';
import { ConfirmDialog } from 'msg-react-lib';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GlobalService } from '../../services/globalService';
import { InvestorService } from '../../services/investorService';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const finishSteps = [0]

function EntityBOStatusDialogStepper(props, ref) {
  const [activeStep, setActiveStep] = React.useState(0);
  const activeButton = false;
  const [dirty, setDirty] = React.useState(false);
  let formikProps: any;

  useImperativeHandle(ref, () => ({
    setStep(step) {
      setActiveStep(step)
    }
  }));

  const setFormikProps = (props) => {
    formikProps = props
  }

  const handleDialogClose = () => {

    if (formikProps !== undefined && formikProps.dirty) {
      setDirty(true)
    } else {
      props.handleClose()
    }
  }

  const handleConfirmDialogConfirm = async () => {
    setDirty(false)
    await submitForm()
    props.handleClose(true)
  }

  const handleConfirmDialogClose = async () => {
    setDirty(false)
    props.handleClose()
  }
  var submitForm = () => { }

  var getNextStep = () => { }

  var getBackStep = () => { }

  var handleClose = () => {
	  setActiveStep(0)
    props.handleClose(true)
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <BeneficialOwnerStatus activeStep={Math.floor(activeStep)}
          controllingPerson={props.controllingPerson} setBoStatus={setBoStatus}
          bindSubmitFormHandler={bindSubmitFormHandler} boStatus={props.entityInvestor.declareHavingUbo}
          bindGetNextStepHandler={bindGetNextStepHandler} handleClose={handleClose} setFormikProps={setFormikProps} />;
      default:
        return '';
    }
  }

	var setBoStatus = async (boStatus: boolean) => {
    if (boStatus === null) {
      GlobalService.sendSnack("error", "Please select an option")
      bindGetNextStepHandler(() => { setActiveStep(0) })
    } else {
      await InvestorService.updateBoDeclaration(boStatus);
		if (boStatus) {
			props.openBeneficialOwnerStepper();
			handleClose();
		} else {
			props.handleClose();
		}
      
    }
  }

  async function handleNext() {
    await submitForm()
    setTimeout(async () => {
      await getNextStep();
    }, 500);
  };

  function handleBack() {
    setTimeout(() => {
      getBackStep();
    }, 500);
  };

  function bindSubmitFormHandler(submitFormHandler) {
    submitForm = submitFormHandler;
  }

  function bindGetNextStepHandler(getNextStepHandler) {
    getNextStep = getNextStepHandler
  }

  const GetButton = () => {
    if (finishSteps.indexOf(activeStep) !== -1) {
      return (
        <Button
          className={styles.nextButton}
          color="primary"
          variant="contained"
          onClick={handleNext}
          disableElevation
        >
          Finish
        </Button>
      )
    } else {
      return (
        <ArrowButton handleNext={handleNext} activeButton={activeButton} />
      )
    }
  }

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
        BackdropProps={{
          className: styles.sideDialogWrap
        }}
        PaperProps={{
          className: styles.sideDialog
        }}
      >
        <DialogContent className={styles.dialogContent}>
 			<IconButton aria-label="close" className={styles.closeButton} onClick={handleDialogClose}>
				<CloseIcon />
			</IconButton>
          {getStepContent(activeStep)}
        </DialogContent>
        <DialogActions>
          <div className={styles.stepButtons}>
            <Button color="primary" disabled={activeStep === 0} onClick={handleBack}>
              <span className={styles.backArrow}></span>
                          Back
                      </Button>
            <GetButton />
          </div>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        isOpen={dirty}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleConfirmDialogConfirm}
        title="Unsaved changes"
        description="You have unsaved changes. Do you want to save it?"
        cancelText="No"
        confirmText="Yes"
      />
    </>
  );
}

const forwardedRef = forwardRef(EntityBOStatusDialogStepper)

export default forwardedRef;