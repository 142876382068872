import React, { Component } from 'react';
import * as Shared from '../../shared/sharedTypes';
import { InvestorService } from '../../services/investorService';
import { GlobalService } from '../../services/globalService';
import { DocumentService } from '../../services/documentService'
import CreateUsTin from './createUsTin';
import SubmitProofOfSurrenderedUsCitizenship from './submitProofOfSurrenderedUsCitizenship';
import styles from './citizenshipStatus.module.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ValidationService } from '../../services/validationService';

const EmptyTaxDetail = {
  id: 0,
  countryCode: 'US',
  taxReferenceNumberType: '',
  taxIdentificationNumber: '',
  taxDetailsNotApplicable: false
}

class State {
  investor: Shared.IIndividualInvestor = Shared.EmptyIndividualInvestor;
  usTaxDetail: Shared.ITaxDetail = EmptyTaxDetail;
  usCitizenDialogOpen: boolean = false;
  fromerUsCitizenDialogOpen: boolean = false;
  usCitizenshipStatus: string = '';
  proofOfSurrenderedUsCitizenship = {
    ...Shared.EmptyDocument,
    title: 'Proof Of Surrendered U.S. Citizenship',
    type: 'ProofOfSurrenderedUsCitizenship'
  }
}

export class CitizenshipStatus extends Component<Shared.IProps, State>{
  state = new State();
  constructor(props: Shared.IProps) {
    super(props);
    this.loadUsTaxDetail = this.loadUsTaxDetail.bind(this);
    this.createUsTinDialogToggle = this.createUsTinDialogToggle.bind(this);
    this.submitFromerUsCitizenDialogToggle = this.submitFromerUsCitizenDialogToggle.bind(this);
    this.createUsTaxDetail = this.createUsTaxDetail.bind(this);
    this.updateInvestorCitizenshipStatus = this.updateInvestorCitizenshipStatus.bind(this);
    this.populateInvestorData = this.populateInvestorData.bind(this);
    this.refreshInvestor = this.refreshInvestor.bind(this)
    this.loadDocuments = this.loadDocuments.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
    this.clearDocument = this.clearDocument.bind(this);
    this.submitUsCitizenOrResident = this.submitUsCitizenOrResident.bind(this);
    this.submitFromerUsCitizen = this.submitFromerUsCitizen.bind(this);
    this.submitNonUsCitizenOrResident = this.submitNonUsCitizenOrResident.bind(this);
  }

  async componentDidMount() {
    if (this.props.investor !== undefined && this.props.investor !== null) {
      await this.setState({ investor: { ...Shared.EmptyIndividualInvestor, ...this.props.investor } })
      this.loadUsTaxDetail()
      this.loadDocuments()
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.investor?.documents !== prevProps.investor.documents) {
      this.loadDocuments();
    }
  }

  private loadUsTaxDetail() {
    let usTaxDetail = this.state.investor!.taxDetails.find(x => x.countryCode === 'US');
    if (usTaxDetail !== undefined) {
      this.setState({
        usTaxDetail: usTaxDetail
      })
    } else {
      this.setState({
        usTaxDetail: EmptyTaxDetail
      })
    }
  }

  private async submitUsCitizenOrResident(event) {
    event.preventDefault();
    await this.updateUsCitizenshipStatus("USCitizenOrResident");
    this.setState({ usCitizenDialogOpen: true });
  }

  private async submitFromerUsCitizen(event) {
    event.preventDefault();
    await this.updateUsCitizenshipStatus("FormerUSCitizen");
    this.setState({ fromerUsCitizenDialogOpen: true });
  }

  private async submitNonUsCitizenOrResident(event) {
    event.preventDefault();
    await this.updateUsCitizenshipStatus("NonUSCitizenOrResident");
    this.props.setActiveStep!(5.1)
  }

  private async updateUsCitizenshipStatus(usCitizenshipStatus: string) {
    await InvestorService.updateUsCitizenshipStatus(usCitizenshipStatus);
    await this.validate();
    await this.updateInvestorCitizenshipStatus(usCitizenshipStatus);
  }

  private createUsTinDialogToggle() {
    this.setState({ usCitizenDialogOpen: !this.state.usCitizenDialogOpen });
  }

  private submitFromerUsCitizenDialogToggle() {
    this.setState({ fromerUsCitizenDialogOpen: !this.state.fromerUsCitizenDialogOpen });
  }

  private async createUsTaxDetail(value: Shared.ITaxDetail) {
    if (value.id !== 0) {
      await this.updateTaxDetail(value);
    } else {
      await this.addTaxDetail(value);
    }
    this.createUsTinDialogToggle();
  }

  private async addTaxDetail(value: Shared.ITaxDetail) {
    await InvestorService.addTaxDetail(value)
      .then(result => {
        if (result.TaxDetail !== undefined) {
          GlobalService.sendSnack(result.success ? "success" : "error", result.TaxDetail)
        } else {
          GlobalService.sendSnack(result.success ? "success" : "error", result.message)
        }
      })
    await this.refreshInvestor();
  }

  private async updateTaxDetail(value: Shared.ITaxDetail) {
    await InvestorService.updateTaxDetail(value)
      .then(result => {
        if (result.TaxDetail !== undefined) {
          GlobalService.sendSnack(result.success ? "success" : "error", result.TaxDetail)
        } else {
          GlobalService.sendSnack(result.success ? "success" : "error", result.message)
        }
      })
    await this.refreshInvestor();
  }

  private async updateInvestorCitizenshipStatus(status: string) {
    let investor = this.state.investor;
    if (investor.usCitizenshipStatus !== status) {
      await this.refreshInvestor();
    }
  }

  private async populateInvestorData() {
    await InvestorService.getInvestor()
      .then(result => {
        this.setState({ investor: { ...Shared.EmptyIndividualInvestor, ...result } });
      })
  };

  private async refreshInvestor() {
    await this.populateInvestorData();
    await this.props.updateInvestor(this.state.investor);
    this.loadUsTaxDetail();
  }

  private loadDocuments() {
    var document = this.props.investor!.documents.find(x => x.type === 'ProofOfSurrenderedUsCitizenship')
    console.log(document);
    if (document !== undefined) {
      document.title = 'Proof Of Surrendered U.S. Citizenship'
      this.setState({ proofOfSurrenderedUsCitizenship: document })
    } else {
      this.setState({
        proofOfSurrenderedUsCitizenship: {
          ...Shared.EmptyDocument,
          title: 'Proof Of Surrendered U.S. Citizenship',
          type: 'ProofOfSurrenderedUsCitizenship'
        }
      })
    }
  }

  async saveDocument(values: Shared.IDocument) {
    await DocumentService.saveDocument(values)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.refreshInvestor();
          this.loadDocuments();
        }
      });
  }

  async uploadDocument(values: Shared.IDocument) {
    if (values.content.files[0] === undefined) {
      return
    }
    const formData = new FormData();
    formData.append('name', values.content.files[0].name);
    formData.append('type', values.type);
    formData.append('content', values.content.files[0]);
    await DocumentService.uploadDocument(formData)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.refreshInvestor();
        }
      });
  }

  async clearDocument(values: Shared.IDocument) {
    await DocumentService.clearDocument(values.id)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.refreshInvestor();
        }
      });
  }

  async handleNext() {
    await this.validate();
  }

  private async validate() {
    await ValidationService.validateInvestor('CitizenshipStatus')
      .then(result => {
        this.props.setCaseIsValid(result.totalIssues === 0)
        GlobalService.sendNotification(result);
      });
  }

  public render() {
    return (
      <div className="stepContent">
        {this.props.bindSubmitFormHandler!(this.handleNext)}
        <h1>FATCA/CRS</h1>
        <div className={`stepDetails`}>
          <div>
            <h3 className="stepCount">{`Step ${this.props.currentStep}`}</h3>
            <h2>Declaration of U.S. Citizenship or Residence for tax purposes</h2>
          </div>
        </div>
        <div className={`stepRight stepDetails`}>
          <FormControlLabel
            style={{ marginLeft: '0' }}
            value="citizenshipStatus"
            label=""
            labelPlacement="start"
            control={
              <RadioGroup name="citizenshipStatus" className="rowWrap">
                <FormControlLabel
                  className={`${styles.radioSection} ${this.state.investor.usCitizenshipStatus === 'USCitizenOrResident' ? styles.active : ''}`}
                  value="USCitizenOrResident"
						control={<Radio color="primary" autoFocus={this.state.investor.usCitizenshipStatus === 'USCitizenOrResident' || this.state.investor.usCitizenshipStatus === '' }  checked={this.state.investor.usCitizenshipStatus === 'USCitizenOrResident'} />}
                  onClick={this.submitUsCitizenOrResident}
                  label={
                    <>
                      <div>1. I confirm that I am a U.S citizen and/or resident in the U.S. for tax purposes (Green Card holder or resident under the substantial presence test) and my U.S. federal taxpayer identification number (I.S. TIN) is as follows</div>
                      {this.state.investor.usCitizenshipStatus === 'USCitizenOrResident' ? <p className={styles.tinString}>TIN: <b>{this.state.usTaxDetail.taxIdentificationNumber}</b></p> : null}
                    </>
                  }
                />
                <FormControlLabel
                  className={`${styles.radioSection} ${this.state.investor.usCitizenshipStatus === 'FormerUSCitizen' ? styles.active : ''}`}
                  value="FormerUSCitizen"
						control={<Radio color="primary" autoFocus={this.state.investor.usCitizenshipStatus === 'FormerUSCitizen'} checked={this.state.investor.usCitizenshipStatus === 'FormerUSCitizen'} />}
                  onClick={this.submitFromerUsCitizen}
                  label={
                    <div>2. I confirm that I was born in the U.S. (or a U.S. territory) but am no longer a U.S citizen as I have voluntarily surrendered my citizenship as evidenced by the attached documents.</div>
                  }
                />
                <FormControlLabel
                  className={`${styles.radioSection} ${this.state.investor.usCitizenshipStatus === 'NonUSCitizenOrResident' ? styles.active : ''}`}
                  value="NonUSCitizenOrResident"
						control={<Radio color="primary" autoFocus={this.state.investor.usCitizenshipStatus === 'NonUSCitizenOrResident'} checked={this.state.investor.usCitizenshipStatus === 'NonUSCitizenOrResident'} />}
                  onClick={this.submitNonUsCitizenOrResident}
                  label={
                    <div>3. I confirm that I am not a U.S citizen or resident in the U.S for tax purposes.</div>
                  }
                />
              </RadioGroup>
            } />

          <CreateUsTin
            isOpen={this.state.usCitizenDialogOpen}
            handleClose={this.createUsTinDialogToggle}
            usTaxDetail={this.state.usTaxDetail}
            handleSubmit={this.createUsTaxDetail}
            confirmText={"Confirm"}
            cancelText={"Cancel"}
          />
          <SubmitProofOfSurrenderedUsCitizenship
            isOpen={this.state.fromerUsCitizenDialogOpen}
            handleClose={this.submitFromerUsCitizenDialogToggle}
            document={this.state.proofOfSurrenderedUsCitizenship}
            uploadDocument={this.uploadDocument}
            saveDocument={this.saveDocument}
            clearDocument={this.clearDocument} />
        </div>
      </div>
    );
  }
}