import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form, Field } from 'formik';
import { TextFieldMaples } from 'msg-react-lib';

export default function CreateUsTin(props) {
	const [fullWidth] = React.useState(false);
	const [maxWidth] = React.useState<DialogProps['maxWidth']>('xs');

	return (
		<Dialog
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			open={props.isOpen}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">US TIN Number</DialogTitle>
			<Formik enableReinitialize initialValues={props.usTaxDetail} onSubmit={props.handleSubmit}>
				{
					({ submitForm }) => (
						<Fragment>
							<DialogContent>
								<Form className="">
									<Field
										autoFocus
										required
										name="taxIdentificationNumber"
										component={TextFieldMaples}
										label="TIN"
									/>
								</Form>
							</DialogContent>
							<DialogActions>
								<Button variant="contained" type="submit" color="primary" onClick={submitForm} disableElevation>{props.confirmText}</Button>
								<Button onClick={props.handleClose} color="primary">{props.cancelText}</Button>
							</DialogActions>
						</Fragment>
					)
				}
			</Formik>
		</Dialog>
	);
}