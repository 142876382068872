import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextFieldMaples, SelectMaples } from 'msg-react-lib';
import * as Shared from '../../../shared/sharedTypes'
import { ControllingPersonService } from '../../../services/controllingPersonService'
import { GlobalService } from '../../../services/globalService';
import { ValidationService } from '../../../services/validationService';

const ControllingPersonTypes = [
	{
		value: 'Individual',
		label: 'Individual'
	},
	{
		value: 'Company',
		label: 'Company'
	},
	{
		value: 'LLC',
		label: 'Limited Liability Company'
	},
	{
		value: 'Partnership',
		label: 'Limited Partnership'
	},
	{
		value: 'Trust',
		label: 'Trust'
	}]

export default function ControllingPersonDetails(props: Shared.IEntityProps) {
	let controllingPerson: Shared.IControllingPerson = Shared.EmptyControllingPerson

	async function populateInvestorData() {
		await props.updateEntityInvestor!();
	}

	async function handleSubmit(values: Shared.IControllingPerson) {
		await ControllingPersonService.save(values)
			.then(async result => {
				if (result.id !== undefined) {
					controllingPerson = result
					await populateInvestorData();
					await props.updateControllingPerson!(controllingPerson)
				} else if (result.Name !== undefined) {
					GlobalService.sendSnack("error", result.Name)
				} else if (!result.success) {
					GlobalService.sendSnack("error", "Entity controlling person name and type are required")
				}
		})
	}

	async function setNextActiveStep() {
		if (controllingPerson.type === '') {
			//this.props.handleClose();      
		} else if (controllingPerson.type === 'Individual') {
			props.setActiveStep!(0.1);
		} else if (controllingPerson.regulatedEntity === true) {
			props.setActiveStep!(0.3);
		} else if (controllingPerson.regulatedEntity === false) {
			props.setActiveStep!(0.4);
		} else {
			props.setActiveStep!(0.2);
		}
	}

	return (
		<>
			<div className="stepContent">
				<h1>Controlling person / Entity details</h1>
				<div className={`stepLeft`}>
					<h2>Entity name and type</h2>
				</div>
				<Formik enableReinitialize initialValues={props.controllingPerson!} onSubmit={handleSubmit}>
					{
						(formikProps) => {
							props.bindSubmitFormHandler!(formikProps.submitForm);
							props.setFormikProps!(formikProps);
							props.bindGetNextStepHandler!(setNextActiveStep);
							return (
									<Form className="stepRight stepDetails">
										<div className={`rowWrap`}>
											<Field
												style={{ marginBottom:0 }}
												autoFocus
												name="name"
												component={TextFieldMaples}
												label="Entity Name"
												required
											/>
											<Field
												name="type"
												label="Type"
												required
												disabled={props.controllingPerson!.type !== ''}
												options={ControllingPersonTypes.map((type) => {
													return (
														{ value: type.value, label: type.label }
													)
												})}
												component={SelectMaples}
											/>
										</div>
									</Form>
							)
						}
					}
				</Formik>
			</div>
		</>
	);
}