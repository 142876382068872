import React, { Component } from 'react';
import img from '../../../assets/errorPage.svg';
import styles from './errorBoundary.module.scss';

interface IProps {

}

interface IState {
    error: any,
    errorInfo: any
}

export class ErrorBoundary extends Component<any, any> {
    constructor(props: IProps) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({
            error: error,
            errorInfo: info
        });
        // Navigate back to the previous screen
        this.props.navigate(-1);
    }

    render() {
        if (this.state.errorInfo) {
			return (
				<div className={styles.container}>
					<div>
						<h2>Oops, Something went wrong</h2>
						<div>It looks like there’s a problem with this page. If you found your way here from outside this site then check the address and try again</div>
						<details className={styles.details}>
							<summary>Error Details</summary>
							{ this.state.error && this.state.error.toString() }
							<br />
							{this.state.errorInfo.componentStack}
						</details>
					</div>
					<img className={styles.img} src={img} alt="Error" />
				</div>
            );
        }
        // Render children if there's no error
        return this.props.children;
    }
}