import React, { forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import styles from './dialogStepper.module.scss';
import ArrowButton from '../animatedComponents/arrowButton/arrowButton';
import { GlobalService } from '../../services/globalService';
import BeneficialOwnerDetails from '../../investors/entity/beneficialOwner/beneficialOwnerDetails'
import { ControllingPersonBenenficialOwnerDocumentsComponent } from '../../investors/entity/beneficialOwner/controllingPersonBeneficialOwnerDocumentComponent'
import { ControllingPersonBeneficialOwnerService } from '../../services/controllingPersonBeneficialOwnerService'
import { ValidationService } from '../../services/validationService';
import * as Shared from '../sharedTypes';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ConfirmDialog } from 'msg-react-lib';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const finishStpes = [1]

function ControllingPersonsBeneficialOwnerDialogStepper(props, ref) {
  const [activeStep, setActiveStep] = React.useState(0);
  const activeButton = false;
	const [dirty, setDirty] = React.useState(false);
	const [activeNotification, setActiveNotification] = React.useState();
  let formikProps: any;

  useImperativeHandle(ref, () => ({
	  setStep(step) {
		  let stepNumber: number = step.stepName === "Documents" ? 1 : 0;
		  setActiveStep(stepNumber)
		  setActiveNotification(step)
    }
  }));

  const setFormikProps = (props) => {    
    formikProps = props
  }

  const handleDialogClose = () => {
    if (formikProps !== undefined && formikProps.dirty) {
      setDirty(true)
    } else {
      props.handleClose()
    }
  }

  const handleConfirmDialogConfirm = async () => {
    setDirty(false)
    await submitForm()
    props.handleClose()
  }

  const handleConfirmDialogClose = async () => {
    setDirty(false)
    props.handleClose()
  }

  var submitForm = () => { }

  var getNextStep = () => {
    setActiveStep(prevActiveStep => Math.floor(prevActiveStep) + 1);
  }

  var getBackStep = () => {
    setActiveStep(prevActiveStep => Math.floor(prevActiveStep) - 1);
  }

  var setBoDetails = async (beneficialOwner: Shared.IBeneficialOwner) => {
    if (props.controllingPerson == null) {
      GlobalService.sendSnack("error", "Controlling Person is unavailable");
      bindGetNextStepHandler(() => { setActiveStep(1) })
    }
    else {
      beneficialOwner.controllingPersonId = props.controllingPerson.id;
      await ControllingPersonBeneficialOwnerService.saveBeneficialOwner(beneficialOwner)
        .then(async result => {
          if (result.id !== undefined) {
            beneficialOwner = { ...beneficialOwner, ...result }
            await props.updateBeneficialOwner(beneficialOwner);            
            await ValidationService.validateControllingPersonBeneficialOwner(beneficialOwner.controllingPersonId, beneficialOwner.id, "Profile", false)
                .then(result => {                
                  GlobalService.sendNotification(result)
                });
            await props.updateEntityInvestor();
          } else if (result.Name !== undefined) {
            GlobalService.sendSnack("error", result.Name)
            bindGetNextStepHandler(() => { setActiveStep(0) })
          } else if (!result.success) {
            GlobalService.sendSnack("error", "A beneficial owner with the same name already exists");
            bindGetNextStepHandler(() => { setActiveStep(0) })
          }
        });        
     }
  }

  var handleClose = () => {
    setActiveStep(0)
    props.handleClose()
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <BeneficialOwnerDetails activeStep={Math.floor(activeStep) + 1}
          controllingPerson={props.controllingPerson} countries={props.countries} handleClose={handleClose} setBoDetails={setBoDetails}
          bindSubmitFormHandler={bindSubmitFormHandler} bindGetNextStepHandler={bindGetNextStepHandler} beneficialOwner={props.beneficialOwner}
			setFormikProps={setFormikProps} activeNotification={activeNotification}/>;
      case 1:
        return <ControllingPersonBenenficialOwnerDocumentsComponent activeStep={Math.floor(activeStep) + 1}
          bindSubmitFormHandler={bindSubmitFormHandler} controllingPerson={props.controllingPerson} beneficialOwner={props.beneficialOwner}
          updateBeneficalOnwer={props.updateBeneficialOwner} bindGetNextStepHandler={bindGetNextStepHandler} handleClose={handleClose} 
			entityInvestor={props.entityInvestor} activeNotification={activeNotification}/>;
      default:
        return '';
    }
  }

  async function handleNext() {
    await submitForm()
    setTimeout(async () => {
      await getNextStep();
    }, 500);
  };

  function handleBack() {
    setTimeout(() => {
      getBackStep();
    }, 500);
  };

  function bindSubmitFormHandler(submitFormHandler) {
    submitForm = submitFormHandler;
  }

  function bindGetNextStepHandler(getNextStepHandler) {
    getNextStep = getNextStepHandler
  }

  const GetButton = () => {
    if (finishStpes.indexOf(activeStep) !== -1) {
      return (
        <Button
          className={styles.nextButton}
          color="primary"
          variant="contained"
          onClick={handleNext}
          disableElevation
        >
          Finish
        </Button>
      )
    } else {
      return (
        <ArrowButton handleNext={handleNext} activeButton={activeButton} />
      )
    }
  }

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
        BackdropProps={{
          className: styles.sideDialogWrap
        }}
        PaperProps={{
          className: styles.sideDialog
        }}
      >
        <DialogContent className={styles.dialogContent}>
		  <IconButton aria-label="close" className={styles.closeButton} onClick={handleDialogClose}>
			<CloseIcon />
		  </IconButton>
          {getStepContent(activeStep)}
        </DialogContent>
        <DialogActions>
          <div className={styles.stepButtons}>
            <Button color="primary" disabled={activeStep === 0} onClick={handleBack}>
              <span className={styles.backArrow}></span>
						Back
					</Button>
            <GetButton></GetButton>
          </div>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        isOpen={dirty}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleConfirmDialogConfirm}
        title="Unsaved changes"
        description="You have unsaved changes. Do you want to save it?"
        cancelText="No"
        confirmText="Yes"
      />
    </>
  );
}

const forwardedRef = forwardRef(ControllingPersonsBeneficialOwnerDialogStepper)

export default forwardedRef;