import React, { useEffect, useState }  from 'react';
import { buildRouter } from "./Router";
import { RouterProvider } from "react-router-dom";
import { ErrorBoundary } from './components/shared/errorBoundary/errorBoundary';

function App() {
	const [displayName, setDisplayName] = useState(App.name);
  const [router, setRouter] = useState();
	useEffect(() => {
		setRouter(buildRouter());
	}, []);
  return (
    <>
		<ErrorBoundary>
			{ router && <RouterProvider router={router} />}
		</ErrorBoundary>
		</>
  );
}

export default App;


