import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextFieldMaples } from 'msg-react-lib';
import { AccountService } from './services/accountService';
import { useNavigate, Link } from "react-router-dom";
import styles from './signIn.module.scss';

export default function SignIn() {
	const [showError, setShowError] = useState(false);
	const navigate = useNavigate();
	const formInitialValues = {
		Username: "",
		Password: ""
	}

	const handleSubmit = async (values) => {
		let success = await AccountService.signIn(values);
		if (success) {
			await navigate('/')
		}
		else {
			setShowError(true);
		}
	}

	return (
		< >
			<h1>Sign-In</h1>
			{showError ? <h3>Invalid username or password</h3> : null}
			<Formik enableReinitialize initialValues={formInitialValues} onSubmit={handleSubmit}>
				{
					({ dirty }) => (
						<Form id="myForm">
							<div className="row">
								<Field name="Username" component={TextFieldMaples} label="Username" className="col-2" />
							</div>
							<div className="row">
								<Field name="Password" component={TextFieldMaples} type="password" label="Password" className="col-2" />
							</div>
							<button
								color="primary"
								type="submit"
								disabled={!dirty}
							>
								Sign-In
							</button>
							<Link to="/forgotPasswordInitiate" className={styles.iconTextLink}>
								Forgot Password?
							</Link>
						</Form>
					)
				}
			</Formik>
		</>);
}