import React, { Component, Fragment } from 'react';
import * as Shared from '../../../shared/sharedTypes'
import styles from './regulatoryStatus.module.scss';
import { Formik, Form, Field } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { ControllingPersonService } from '../../../services/controllingPersonService'
import { GlobalService } from '../../../services/globalService';

interface IState {
	regulatedEntity: boolean | null
}

export class ControllingPersonRegulatoryStatus extends Component<Shared.IEntityProps, IState> {
	constructor(props: Shared.IEntityProps) {
		super(props);
		this.state = {
			regulatedEntity: this.props.controllingPerson!.regulatedEntity
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setNextActiveStep = this.setNextActiveStep.bind(this)
	}

	async handleSubmit(values: Shared.IControllingPerson) {
		if (this.state.regulatedEntity === null) {
			GlobalService.sendSnack("error", "A regulatory status is required")
		} else {
			values.regulatedEntity = this.state.regulatedEntity;
			let controllingPerson: any = {
				id: values.id,
				regulatedEntity: this.state.regulatedEntity,
			};
			await ControllingPersonService.updateRegulatoryStauts(controllingPerson)
				.then(async result => {
					if (result.success) {
						await this.props.updateControllingPerson!(values);
					} else {
						GlobalService.sendSnack("error", result.message)
					}
				});
		}
	}

	async setNextActiveStep() {
		if (this.state.regulatedEntity !== null) {
			this.props.setActiveStep!(this.state.regulatedEntity === true ? 0.3 : 0.4);
		}
	}

	handleChange(e) {
		this.setState({
			regulatedEntity: e.target.value === "true" ? true : false
		})
	}

	public render() {
		return (
			<div className="stepContent">
				<Formik enableReinitialize initialValues={this.props.controllingPerson!} onSubmit={this.handleSubmit}>
					{
						(formikProps) => {
							this.props.bindSubmitFormHandler!(formikProps.submitForm);
							this.props.setFormikProps!(formikProps);
							this.props.bindGetNextStepHandler!(this.setNextActiveStep);
							return (
								<>
									<h1>Entity Selected</h1>
									<div className={`stepLeft`}>
										<div className={`overflowText`}>
											<h2>Is the Entity Regulated?</h2>
											<article>The Subscriber is subject to regulatory oversignt ("<b>Regulated Entity</b>") or is a majority owned subsidiary of a Regulated Entity.<br /><br />
												<i>Note: A <b>majority owned subsidiary </b> is where the parent directly or indirectly owns more than 50% of the shares of the entity).</i>
											</article>
										</div>
									</div>
									<Form className={'stepRight stepDetails'}>
										<FormControlLabel
											style={{ margin: '0' }}
											value="regulatedEntity"
											label=""
											labelPlacement="start"
											control={
												<Field name="regulatedEntity">
													{({
														field, onChange
													}) => (
														<RadioGroup name="regulatedEntity" value={field} className={`rowWrap ${styles.rowWrap}`} {...field}>
														
																<FormControlLabel
																	className={`${styles.radioSection} ${this.props.controllingPerson?.regulatedEntity === true ? styles.active : ''}`}
																	value="true"
																control={<Radio autoFocus={this.props.controllingPerson?.regulatedEntity === true || this.props.controllingPerson?.regulatedEntity === null } color="primary" />}
																	onChange={(e) => this.handleChange(e)}
																	label={
																		<Fragment>
																			<div>Yes</div>
																		</Fragment>
																	}
																/>
																<FormControlLabel
																	className={`${styles.radioSection}  ${this.props.controllingPerson?.regulatedEntity === false ? styles.active : ''}`}
																	value="false"
																	control={<Radio color="primary" checked={this.state.regulatedEntity === false} />}
																	onChange={(e) => this.handleChange(e)}
																	label={
																		<Fragment>
																			<div>No</div>
																		</Fragment>
																	}
																/>
															</RadioGroup>
														)}
												</Field>
											} />
									</Form>
								</>
							)
						}
					}
				</Formik>
			</div>
		)
	}
}