import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function SubmitIndividual(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Ready to submit</DialogTitle>
      <DialogContent>
        Do you want to submit
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleConfirm} variant="contained" color="primary" disableElevation>Yes</Button>
        <Button onClick={props.handleClose} variant="contained" color="info" disableElevation>No</Button>
      </DialogActions>
    </Dialog>
  );
}