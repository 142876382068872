import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form, Field } from 'formik';
import { TextFieldMaples, SelectMaples } from 'msg-react-lib';

export default function CreateTaxDetail(props) {
	const [fullWidth] = React.useState(false);
	const [maxWidth] = React.useState<DialogProps['maxWidth']>('xs');
	const [disabled, setDisabled] = React.useState(false);

	function handleTaxDetailsCheckboxClick(value: boolean) {
		setDisabled(value);
	}

	return (
		<Dialog
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			open={props.isOpen}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Tax Residency</DialogTitle>
			<Formik enableReinitialize initialValues={props.taxDetail} onSubmit={props.handleSubmit}>
				{
					({ submitForm }) => (
						<Fragment>
							<DialogContent>
								<Form id="myForm">
									<div className="row">
										<Field
											autoFocus
											name="countryCode"
											label="Country of Tax Residency"
											style={{ 'width': 300 }}
											options={props.countries!.map((country) => {
												return (
													{ value: country.code, label: country.name }
												)
											})}
											component={SelectMaples}
										/>
									</div>
									<div className="row">
										<Field style={{ 'width': 300, 'margin-bottom':0 }} name="taxReferenceNumberType" component={TextFieldMaples} label="Tax Reference Number Type" disabled={disabled} />
									</div>
									<div className="row">
										<Field style={{ 'width': 300, 'margin-bottom': 0 }} name="taxIdentificationNumber" multiline component={TextFieldMaples} label="Tax Reference Number" disabled={disabled} />
									</div>
									<div>
										<FormControlLabel style={{ marginLeft: '0' }} value="taxDetailsNotApplicable" label="Tax Details Not Available" labelPlacement="end" control={
											<Field name="taxDetailsNotApplicable">
												{({
													field
												}) => (
														<Checkbox color="primary" name="taxDetailsNotApplicable" onClick={handleTaxDetailsCheckboxClick(field.value)} checked={!!field.value} {...field} />
													)}
											</Field>
										} />
									</div>
								</Form>
							</DialogContent>
							<DialogActions>
								<Button variant="contained" color="primary" type="submit" onClick={submitForm} disableElevation>{props.confirmText}</Button>
								<Button onClick={props.handleClose} color="primary">{props.cancelText}</Button>
							</DialogActions>
						</Fragment>
					)
				}
			</Formik>
		</Dialog>
	);
}