import React, { forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import styles from './dialogStepper.module.scss';
import { EntityProfile } from '../../investors/entity/profile';
import { InvestorAddress } from '../../investors/investorAddress';
import { DueDiligenceConsent } from '../../investors/dueDiligenceConsent';
import { DataProtection } from '../../investors/dataProtection';
import { EnhancedDueDiligence } from '../../investors/enhancedDueDiligence';
import { FatcaCrs } from '../../investors/entity/fatcaCrs';
import InvestorDocuments from '../../investors/investorDocuments';
import ArrowButton from '../animatedComponents/arrowButton/arrowButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ConfirmDialog } from 'msg-react-lib';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const maxSteps = 7;

function EntityDialogStepper(props, ref) {
	const [activeStep, setActiveStep] = React.useState(0);
	// caseIsValid is unused here, even though we have validation on the entity dialog - as it's a required prop for most of the steps i'm leaving it in place until we're sure it's safe to remove
	const [caseIsValid, setCaseIsValid] = React.useState(true);
	const [dirty, setDirty] = React.useState(false);
	const [activeNotification, setActiveNotification] = React.useState(); 
	const activeButton = false;
	const finalStep = maxSteps - (props.investor.fatcaCrsInScope ? 0 : 1) - 1;
	let formikProps: any;

	useImperativeHandle(ref, () => ({
		setStep(step) {
			setActiveNotification(step);
			setActiveStep(step.step);
		}
	}));

	var submitForm = () => { }

	var getNextStep = () => {
		if (!props.investor.fatcaCrsInScope && (Math.floor(activeStep) + 1) === 6) {
			setActiveStep(7);
		} else {
			setActiveStep(prevActiveStep => Math.floor(prevActiveStep) + 1);
		}
	}

	var getBackStep = () => {
		if (!props.investor.fatcaCrsInScope && (Math.ceil(activeStep) - 1) === 6) {
			setActiveStep(5);
		} else {
			setActiveStep(prevActiveStep => Math.ceil(prevActiveStep) - 1);
		}
	}

	var handleClose = () => {
		props.finishMainWorkflow();
	}

	const setFormikProps = (props) => {
		formikProps = props
	}

	const handleDialogClose = () => {
		setActiveNotification(undefined);
		if (formikProps !== undefined && formikProps.dirty && props.investor.mainWorkflowCompleted) {
			setDirty(true)
		} else {
			props.handleClose()
		}
	}

	const handleConfirmDialogConfirm = async () => {
		setDirty(false)
		await submitForm()
		props.handleClose()
	}

	const handleConfirmDialogClose = async () => {
		setDirty(false)
		props.handleClose()
	}

	function getStepContent(step) {
		switch (step) {
			case 0:
				return <DataProtection currentStep={1} activeStep={Math.floor(activeStep) + 1} setActiveStep={setActiveStep}
					countries={props.countries} investor={props.investor} updateInvestor={props.updateEntityInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
					setFormikProps={setFormikProps} setCaseIsValid={setCaseIsValid} bindGetNextStepHandler={bindGetNextStepHandler} />;
			case 1:
				return <EntityProfile activeStep={Math.floor(activeStep) + 1}
					countries={props.countries} entityInvestor={props.investor} updateEntityInvestor={props.updateEntityInvestor}
					updateControllingPerson={props.updateControllingPerson} bindSubmitFormHandler={bindSubmitFormHandler}
					setFormikProps={setFormikProps} activeNotification={activeNotification}/>;
			case 2:
				return <InvestorAddress currentStep={3} activeStep={Math.floor(activeStep) + 1}
					countries={props.countries} investor={props.investor} updateInvestor={props.updateEntityInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
					setCaseIsValid={setCaseIsValid} activeNotification={activeNotification}/>;
			case 3:
				return <EnhancedDueDiligence currentStep={4} activeStep={Math.floor(activeStep) + 1}
					countries={props.countries} investor={props.investor} updateInvestor={props.updateEntityInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
					setFormikProps={setFormikProps} setCaseIsValid={setCaseIsValid} activeNotification={activeNotification}/>;
			case 4:
				return <DueDiligenceConsent currentStep={5} activeStep={Math.floor(activeStep) + 1}
					countries={props.countries} investor={props.investor} updateInvestor={props.updateEntityInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
					setFormikProps={setFormikProps} setCaseIsValid={setCaseIsValid} activeNotification={activeNotification}/>;
			case 5:
				return <InvestorDocuments currentStep={6} activeStep={Math.floor(activeStep) + 1}
					investor={props.investor} updateInvestor={props.updateEntityInvestor} bindSubmitFormHandler={bindSubmitFormHandler} setCaseIsValid={setCaseIsValid}
					finalStep={!props.investor.fatcaCrsInScope} activeNotification={activeNotification}/>;
			case 6:
				return <FatcaCrs activeStep={Math.floor(activeStep) + 1}
					countries={props.countries} investor={props.investor} updateInvestor={props.updateEntityInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
					setCaseIsValid={setCaseIsValid} activeNotification={activeNotification}/>;
			default:
				return ''
		}
	}

	async function handleNext() {
		await submitForm()
		if (activeStep === finalStep) {
			handleClose()
		} else {
			setTimeout(async () => {
				await getNextStep();
			}, 500);
		}
	};

	function handleBack() {
		setTimeout(() => {
			getBackStep();
		}, 500);
	};

	function bindSubmitFormHandler(submitFormHandler) {
		submitForm = submitFormHandler;
	}


	function bindGetNextStepHandler(getNextStepHandler) {
		getNextStep = getNextStepHandler
	}

	const GetButton = () => {
		if (activeStep === finalStep) {
			return (
				<Button
					className={styles.nextButton}
					color="primary"
					variant="contained"
					onClick={handleNext}
					disableElevation
				>
					Finish
				</Button>
			)
		} else if (activeStep === maxSteps) {
			return (null)
		}
		else {
			return (
				<ArrowButton handleNext={handleNext} activeButton={activeButton} />
			)
		}
	}

	return (
		<>
			<Dialog
				open={props.isOpen}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth='md'
				disableEnforceFocus={true}
				BackdropProps={{
					className: styles.sideDialogWrap
				}}
				PaperProps={{
					className: styles.sideDialog
				}}
			>
				<DialogContent className={styles.dialogContent}>
					<IconButton aria-label="close" className={styles.closeButton} onClick={handleDialogClose}>
						<CloseIcon />
					</IconButton>
					{getStepContent(activeStep)}
				</DialogContent>
				<DialogActions>
					<div className={styles.stepButtons}>
						<Button color="primary" disabled={activeStep === 0} onClick={handleBack}>
							<span className={styles.backArrow}></span>
						Back
					</Button>
						<GetButton></GetButton>
					</div>
				</DialogActions>
			</Dialog>
			<ConfirmDialog
				isOpen={dirty}
				handleClose={handleConfirmDialogClose}
				handleConfirm={handleConfirmDialogConfirm}
				title="Unsaved changes"
				description="You have unsaved changes. Do you want to save it?"
				cancelText="No"
				confirmText="Yes"
			/>
		</>
	);
}

const forwardedRef = forwardRef(EntityDialogStepper)

export default forwardedRef;