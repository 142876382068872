import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as Shared from '../../shared/sharedTypes';
import CreateTaxDetail from './createTaxDetail';
import { InvestorService } from '../../services/investorService';
import { GlobalService } from '../../services/globalService';
import Checkbox from '@mui/material/Checkbox';
import styles from './taxResidencies.module.scss';
import { ConfirmDialog } from 'msg-react-lib';
import { ValidationService } from '../../services/validationService';

const EmptyTaxDetail = {
  id: 0,
  countryCode: '',
  taxReferenceNumberType: '',
  taxIdentificationNumber: '',
  taxDetailsNotApplicable: false
}

export class TaxResidencies extends Component<Shared.IProps, any>{
  constructor(props: Shared.IProps) {
    super(props);
    if (this.props.investor !== undefined && this.props.investor !== null) {
      this.state = {
        investor: this.props.investor,
        taxDetail: EmptyTaxDetail,
        taxDetailDialogOpen: false,
        confirmDialogOpen: false,
        activeId: 0
      };
    } else {
      this.state = {
        investor: Shared.EmptyIndividualInvestor,
        taxDetail: EmptyTaxDetail,
        taxDetailDialogOpen: false,
        confirmDialogOpen: false,
        activeId: 0
      };
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.confirmTaxResidenceDelete = this.confirmTaxResidenceDelete.bind(this);
    this.clickDeleteTaxDetail = this.clickDeleteTaxDetail.bind(this);
    this.confirmDialogToggle = this.confirmDialogToggle.bind(this);
    this.createTaxDetailDialogToggle = this.createTaxDetailDialogToggle.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  async populateInvestorData() {
    await InvestorService.getInvestor()
      .then(result => {
        this.setState({ investor: result });
      })
    await this.props.updateInvestor(this.state.investor);
  }

  async clickAddTaxDetail() {
    this.setState({
      taxDetail: EmptyTaxDetail
    })
    this.setState({ taxDetailDialogOpen: true });
  }

  clickEditTaxDetail(id: number) {
    let taxDetail = this.state.investor.taxDetails.find(x => x.id === id)
    if (taxDetail === undefined) {
      GlobalService.sendSnack("error", "tax detail not found")
    } else {
      this.setState({
        taxDetail: taxDetail
      })
      this.setState({ taxDetailDialogOpen: true });
    }
  }

  async handleSubmit(values: Shared.ITaxDetail) {
    if (this.state.taxDetail.id === 0) {
      await this.submitAddTaxDetail(values)
    } else {
      await this.submitUpdateTaxDetail(values)
    }
    this.setState({ taxDetailDialogOpen: false });
    await this.populateInvestorData();
  }

  async submitAddTaxDetail(values: Shared.ITaxDetail) {
    await InvestorService.addTaxDetail(values)
      .then(result => {
        if (result.TaxDetail !== undefined) {
          GlobalService.sendSnack(result.success ? "success" : "error", result.TaxDetail)
        } else {
          GlobalService.sendSnack(result.success ? "success" : "error", result.message)
        }
      })
  }

  async submitUpdateTaxDetail(values: Shared.ITaxDetail) {
    await InvestorService.updateTaxDetail(values)
      .then(result => {
        if (result.TaxDetail !== undefined) {
          GlobalService.sendSnack(result.success ? "success" : "error", result.TaxDetail)
        } else {
          GlobalService.sendSnack(result.success ? "success" : "error", result.message)
        }
      })
  }

  async clickDeleteTaxDetail() {
    await InvestorService.deleteTaxDetail(this.state.activeId)
      .then(result => {
        if (result.TaxDetail !== undefined) {
          GlobalService.sendSnack(result.success ? "success" : "error", result.TaxDetail)
        } else {
          GlobalService.sendSnack(result.success ? "success" : "error", result.message)
        }
      })
    this.confirmDialogToggle();
    this.populateInvestorData();
  }

  confirmTaxResidenceDelete(taxResidenceId) {
    this.setState({ activeId: taxResidenceId }, () => {
      this.confirmDialogToggle();
    });

  }

  createTaxDetailDialogToggle() {
    this.setState({ taxDetailDialogOpen: !this.state.taxDetailDialogOpen });
  }

  confirmDialogToggle() {
    this.setState({ confirmDialogOpen: !this.state.confirmDialogOpen });
  }

  async handleNext() {
    await ValidationService.validateInvestor('TaxResidencies')
      .then(result => {
        this.props.setCaseIsValid(result.totalIssues === 0)
        GlobalService.sendNotification(result)
      })
  }

  render() {
    return (
      <div className={`stepContent ${styles.taxResidencies}`}>
        {this.props.bindSubmitFormHandler!(this.handleNext)}
        <h1>FATCA/CRS</h1>
        <div className={`stepDetails`}>
          <div>
            <h3 className="stepCount">{`Step ${this.props.currentStep}`}</h3>
            <h2>Tax Jurisdictions</h2>
            <article>Please provide information on your country of tax residency. <br /><br />Where multiple residencies apply, add additional records as needed.</article>
          </div>
        </div>
        <div className={`stepRight stepDetails`}>
          <div>
            <button className={`iconTextButton ${styles.addButton}`} onClick={() => this.clickAddTaxDetail()}>
              <span className="icon-add mediumIcon"></span>
						New Tax Residency
				  </button>
            <Table className={styles.residenceTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Country of Tax Residency</TableCell>
                  <TableCell>Tax Reference Number Type</TableCell>
                  <TableCell>Tax Reference Number</TableCell>
                  <TableCell>N/A</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.investor.taxDetails.map((taxDetail, index) => (
                  <TableRow key={index} hover>
                    <TableCell align="left">
                      <button className={styles.iconTextButton} onClick={() => this.clickEditTaxDetail(taxDetail.id)}>
                        {this.props.countries?.find(x => x.code === taxDetail.countryCode)!.name}
                      </button>
                    </TableCell>
                    <TableCell align="left">{taxDetail.taxReferenceNumberType}</TableCell>
                    <TableCell align="left">{taxDetail.taxIdentificationNumber}</TableCell>
                    <TableCell align="left">
                      <Checkbox
                        disabled
                        checked={taxDetail.taxDetailsNotApplicable}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.buttonCell}>
                      <button title="Delete" onClick={() => this.confirmTaxResidenceDelete(taxDetail.id)} className="iconButton mediumIcon icon-remove"></button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        <CreateTaxDetail
          isOpen={this.state.taxDetailDialogOpen}
          handleClose={this.createTaxDetailDialogToggle}
          handleSubmit={this.handleSubmit}
          confirmText={this.state.taxDetail.id === 0 ? "Add" : "Update"}
          cancelText={"Cancel"}
          maxWidth='xs'
          taxDetail={this.state.taxDetail}
          countries={this.props.countries} />
        <ConfirmDialog
          isOpen={this.state.confirmDialogOpen}
          handleClose={this.confirmDialogToggle}
          handleConfirm={this.clickDeleteTaxDetail}
          title="Are You Sure?"
          description="Do you want to delete this tax residency?"
          cancelText="Cancel"
          confirmText="Delete"
        />
      </div>
    )
  }
}