import React, { Component } from 'react';
import * as Shared from '../../../../shared/sharedTypes'
import Document from '../../../../shared/documentUpload/documentComponent';
import { GlobalService } from '../../../../services/globalService';
import { ControllingPersonService } from '../../../../services/controllingPersonService';
import { ControllingPersonDocumentService } from '../../../../services/controllingPersonDocumentService';
import MessageDialog from '../../../../shared/dialogs/dialogMessage';
import { ValidationService } from '../../../../services/validationService';
import styles from "../../../../../assets/styles/notifications.module.scss";

const structureChartTitle: string = 'Structure Chart (optional)';
const structureChartType: string = 'OwnershipOrOrganizationalChart';
const proofOfRegulatedStatusTitle: string = 'Either Proof of Regulated Status or Subsidiary';
const proofOfRegulatedStatusType: string = 'ProofOfRegulatedStatusOrSubsidiary';

class State {
  guideTitle: string = "Upload Documents"
  guideDescription: string = "To confirm ..."
  guideDialogOpen: boolean = false
  structureChart: Shared.IDocument = {
    ...Shared.EmptyDocument,
    title: structureChartTitle,
    type: structureChartType
  }
  regulatoryStatus: Shared.IDocument = {
    ...Shared.EmptyDocument,
    title: proofOfRegulatedStatusTitle,
    type: proofOfRegulatedStatusType
  }
}
export class RegulatedControllingPersondDocuments extends Component<Shared.IEntityProps> {
  state = new State();
  constructor(props: Shared.IEntityProps) {
    super(props)
    this.loadDocuments = this.loadDocuments.bind(this);
    this.setNextActiveStep = this.setNextActiveStep.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
    this.clearDocument = this.clearDocument.bind(this);
    this.validateControllingPersonDocuments = this.validateControllingPersonDocuments.bind(this);
  }

  componentDidMount() {
    if (this.props.controllingPerson?.documents !== undefined) {
      this.loadDocuments()
    }
  }

  componentDidUpdate(prevProps) {
		if (prevProps.controllingPerson.documents !== this.props.controllingPerson?.documents) {
			this.loadDocuments();
		}
	}

  private async loadDocuments() {
    var document: Shared.IDocument | undefined = this.props.controllingPerson!.documents.find(x => x.type === structureChartType);
    if (document !== undefined) {
      document.title = structureChartTitle
      document.controllingPersonId = this.props.controllingPerson!.id
      this.setState({ structureChart: document })
    } else {
      this.setState({
        structureChart: {
          ...Shared.EmptyDocument,
          title: structureChartTitle,
          type: structureChartType,
          controllingPersonId: this.props.controllingPerson!.id
        }
      })
    }

    document = this.props.controllingPerson!.documents.find(x => x.type === proofOfRegulatedStatusType)
    if (document !== undefined) {
      document.title = proofOfRegulatedStatusTitle
      document.controllingPersonId = this.props.controllingPerson!.id
      this.setState({ regulatoryStatus: document })
    } else {
      this.setState({
        regulatoryStatus: {
          ...Shared.EmptyDocument,
          title: proofOfRegulatedStatusTitle,
          type: proofOfRegulatedStatusType,
          controllingPersonId: this.props.controllingPerson!.id
        }
      })
    }
  }

  async saveDocument(values: Shared.IDocument) {
    await ControllingPersonDocumentService.saveDocument(values)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.populateControllingPersonData(values.controllingPersonId);
          await this.validateControllingPersonDocuments();
        }
      });
  }

  async uploadDocument(values: Shared.IDocument) {
    if (values.content.files[0] === undefined) {
      return
    }
    const formData = new FormData();
    formData.append('name', values.content.files[0].name);
    formData.append('type', values.type);
    formData.append('content', values.content.files[0]);
    formData.append('ControllingPersonId', values.controllingPersonId);
    await ControllingPersonDocumentService.uploadDocument(formData)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.populateControllingPersonData(values.controllingPersonId);
          await this.validateControllingPersonDocuments();
        }
      });
  }

  async clearDocument(values: Shared.IDocument) {
    await ControllingPersonDocumentService.clearDocument(values.id, values.controllingPersonId)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.populateControllingPersonData(values.controllingPersonId);
          await this.validateControllingPersonDocuments();
        }
      });
  }

  async populateControllingPersonData(id: string) {
    await ControllingPersonService.getControllingPerson(id)
      .then(async result => {
        await this.props.updateControllingPerson!(result);
        await this.loadDocuments();
      })
  };

  async setNextActiveStep() {
    await this.validateControllingPersonDocuments();
    this.props.handleClose!();
  }

  async validateControllingPersonDocuments() {
    await ValidationService.validateControllingPerson(this.props.controllingPerson!.id, "Documents")
      .then(result => {
        GlobalService.sendNotification(result)
      });
  }

  render() {
    return (
      <>
        {this.props.bindGetNextStepHandler!(this.setNextActiveStep)}
        <div className="stepContent">
          <h1>A Regulated Entity</h1>
          <div className={`stepLeft`}>
            <div className={`overflowText`}>
              <h2>Upload Documents</h2>
              <article>
                <p>To confirm the regulated status of this entity, please provide:<br /><br />1) Proof of either regulated status or a subsidiary of a regulated entity<br />2) Attach structure chart where subsidiary or regulated entity</p>
              </article>
            </div>
          </div>
          <div className={`stepRight stepDetails`}>
			<div className="rowWrap">
              <Document
                document={this.state.regulatoryStatus}
                uploadDocument={this.uploadDocument}
                saveDocument={this.saveDocument}
							clearDocument={this.clearDocument}
							classes={`${(this.props.activeNotification?.notificationKey === this.state.regulatoryStatus.type && this.state.regulatoryStatus.name === "") ? styles.hasNotification : null}`}
						/>
              <Document
                document={this.state.structureChart}
                uploadDocument={this.uploadDocument}
                saveDocument={this.saveDocument}
							clearDocument={this.clearDocument}
							classes={`${(this.props.activeNotification?.notificationKey === this.state.structureChart.type && this.state.structureChart.name === "") ? styles.hasNotification : null}`}
						/>
            </div>
          </div>
        </div>
        <MessageDialog
          isOpen={this.state.guideDialogOpen}
          handleClose={() => { this.setState({ guideDialogOpen: !this.state.guideDialogOpen }) }}
          title={this.state.guideTitle}
          description={this.state.guideDescription}
          cancelText="Cancel"
          confirmText="Delete"
        />
      </>
    );
  }
}