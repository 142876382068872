import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextFieldMaples } from 'msg-react-lib';
import { Formik, Form, Field } from "formik";
import Button from '@mui/material/Button';
import { AccountService } from '../services/accountService';

export default function ForgotPasswordVerify(props) {
	const location = useLocation();
	const [showError, setShowError] = useState(false);
	const navigate = useNavigate();
	const formInitialValues = {
		code: ''
	}

	const handleVerify = async (values) => {
		let success = await AccountService.checkRecoveryCode({ recoveryCode: values.code, flowId: location.state.id });
		if (success) {
			navigate('/forgotPasswordReset',{
				state: { id: location.state.id, userId: location.state.userId }
			});
		}
		else {
			setShowError(true);
		}
	};

	return (
		<div>
			<h3>Password recovery email sent</h3>
			{showError ? <h3>Error occured. Please try again after 5 minutes.</h3> : null}
			<h5>We have sent an email to your email address. It will contain a code to enter below.</h5>
			<Formik enableReinitialize initialValues={formInitialValues} onSubmit={handleVerify}>
				{
					({ dirty }) => (
						<Form>
							<Field name="code" component={TextFieldMaples} label="Recovery code" required />
							<Button type="submit" color="primary" variant="contained" disabled={!dirty}>Next</Button>
						</Form>
					)
				}
			</Formik>
		</div>
	);
}