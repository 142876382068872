import React, { forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import styles from './dialogStepper.module.scss';
import ArrowButton from '../animatedComponents/arrowButton/arrowButton';
import { IndividualControllingPersondDocuments } from '../../investors/entity/controllingPerson/documents/individualDocuments';
import { RegulatedControllingPersondDocuments } from '../../investors/entity/controllingPerson/documents/regulatedDocumets';
import { ControllingPersonRegulatoryStatus } from '../../investors/entity/controllingPerson/regulatoryStatus';
import ControllingPersonDetails from '../../investors/entity/controllingPerson/details';
import { NonRegulatedControllingPersondDocuments } from '../../investors/entity/controllingPerson/documents/nonRegulatedDocuments';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ConfirmDialog } from 'msg-react-lib';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const finishSteps = [0.1, 0.3, 0.4]

function ControllingPersonDialogStepper(props, ref) {
	const [activeStep, setActiveStep] = React.useState(0);
	const [dirty, setDirty] = React.useState(false);
	const [activeNotification, setActiveNotification] = React.useState(); 
	let formikProps: any;

	const activeButton = false;

	useImperativeHandle(ref, () => ({
		setStep(step) {
			if (step.step) {
				setActiveNotification(step);
				setActiveStep(step.step)
			} else {
				setActiveStep(step)
			}
			
		}
	}));

	const setFormikProps = (props) => {
		formikProps = props
	}

	const handleDialogClose = () => {
		setActiveNotification(undefined);
		if (formikProps !== undefined && formikProps.dirty) {
			setDirty(true)
		} else {
			props.handleClose()
		}
	}

	const handleConfirmDialogConfirm = async () => {
		setDirty(false)
		await submitForm()
		props.handleClose()
	}

	const handleConfirmDialogClose = async () => {
		setDirty(false)
		props.handleClose()
	}

	var submitForm = () => { }

	var getNextStep = () => { }

	var getBackStep = () => { setActiveStep(0); }

	var handleClose = () => {
		setActiveStep(0)
		props.handleClose()
	}

	function getStepContent(step) {
		switch (step) {
			case 0:
				return <ControllingPersonDetails activeStep={Math.floor(activeStep) + 1} setActiveStep={setActiveStep}
					entityInvestor={props.entityInvestor} controllingPerson={props.controllingPerson}
					updateEntityInvestor={props.updateEntityInvestor} updateControllingPerson={props.updateControllingPerson}
					bindSubmitFormHandler={bindSubmitFormHandler} bindGetNextStepHandler={bindGetNextStepHandler} handleClose={handleClose}
					setFormikProps={setFormikProps} />;
			case 0.1:
				return <IndividualControllingPersondDocuments activeStep={Math.floor(activeStep) + 1} setActiveStep={setActiveStep}
					entityInvestor={props.entityInvestor} controllingPerson={props.controllingPerson}
					updateControllingPerson={props.updateControllingPerson} bindGetNextStepHandler={bindGetNextStepHandler}
					bindGetBackStepHandler={bindGetBackStepHandler} handleClose={handleClose} activeNotification={activeNotification}/>;
			case 0.2:
				return <ControllingPersonRegulatoryStatus activeStep={Math.floor(activeStep) + 1} setActiveStep={setActiveStep}
					entityInvestor={props.entityInvestor} controllingPerson={props.controllingPerson}
					updateControllingPerson={props.updateControllingPerson}
					bindSubmitFormHandler={bindSubmitFormHandler} bindGetNextStepHandler={bindGetNextStepHandler} handleClose={handleClose}
					setFormikProps={setFormikProps} />;
			case 0.3:
				return <RegulatedControllingPersondDocuments activeStep={Math.floor(activeStep) + 1} setActiveStep={setActiveStep}
					entityInvestor={props.entityInvestor} controllingPerson={props.controllingPerson}
					updateControllingPerson={props.updateControllingPerson} bindGetNextStepHandler={bindGetNextStepHandler}
					bindGetBackStepHandler={bindGetBackStepHandler} handleClose={handleClose} activeNotification={activeNotification}/>;
			case 0.4:
				return <NonRegulatedControllingPersondDocuments activeStep={Math.floor(activeStep) + 1} setActiveStep={setActiveStep}
					entityInvestor={props.entityInvestor} controllingPerson={props.controllingPerson}
					updateControllingPerson={props.updateControllingPerson}
					bindSubmitFormHandler={bindSubmitFormHandler} bindGetNextStepHandler={bindGetNextStepHandler}
					bindGetBackStepHandler={bindGetBackStepHandler} handleClose={handleClose} activeNotification={activeNotification}/>;
			default:
				return ''
		}
	}

	async function handleNext() {
		await submitForm()
		setTimeout(async () => {
			await getNextStep();
		}, 500);
	};

	function handleBack() {
		setTimeout(() => {
			getBackStep();
		}, 500);
	};

	function bindSubmitFormHandler(submitFormHandler) {
		submitForm = submitFormHandler;
	}

	function bindGetNextStepHandler(getNextStepHandler) {
		getNextStep = getNextStepHandler
	}

	function bindGetBackStepHandler(getBackStepHandler) {
		getBackStep = getBackStepHandler;
	}

	const GetButton = () => {
		if (finishSteps.indexOf(activeStep) !== -1) {
			return (
				<Button
					className={styles.nextButton}
					color="primary"
					variant="contained"
					onClick={handleNext}
					disableElevation
				>
					Finish
				</Button>
			)
		} else {
			return (
				<ArrowButton handleNext={handleNext} activeButton={activeButton} />
			)
		}
	}

	return (
		<>
			<Dialog
				open={props.isOpen}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth='md'
				BackdropProps={{
					className: styles.sideDialogWrap
				}}
				PaperProps={{
					className: styles.sideDialog
				}}
			>
				<DialogContent className={styles.dialogContent}>
					<IconButton aria-label="close" className={styles.closeButton} onClick={handleDialogClose}>
						<CloseIcon />
					</IconButton>
					{getStepContent(activeStep)}
				</DialogContent>
				<DialogActions>
					<div className={styles.stepButtons}>
						<Button color="primary" disabled={activeStep === 0} onClick={handleBack}>
							<span className={styles.backArrow}></span>
						Back
					</Button>
						<GetButton></GetButton>
					</div>
				</DialogActions>
			</Dialog>
			<ConfirmDialog
				isOpen={dirty}
				handleClose={handleConfirmDialogClose}
				handleConfirm={handleConfirmDialogConfirm}
				title="Unsaved changes"
				description="You have unsaved changes. Do you want to save it?"
				cancelText="No"
				confirmText="Yes"
			/>
		</>
	);
}

const forwardedRef = forwardRef(ControllingPersonDialogStepper)

export default forwardedRef;