import React, { useState, useEffect } from 'react';
import * as Shared from '../sharedTypes';
import UploadDocument from './documentUploader';
import DocumentInfo from './documentInfo';
import styles from './documentComponent.module.scss';
import CircularProgress from '@mui/material/CircularProgress';

export default function Document(props) {
  const [documentInfoDialogOpen, setDocumentInfoDialogOpen] = useState(false);
  const [dragState, setDragState] = useState('');
  const [dragCount, setDragCount] = useState(0);
  const [hasDoc, setHasDoc] = useState(false);
  const [hasNote, setHasNote] = useState(false);

  useEffect(() => {
    setHasNote(props.document.notes !== "");
    setHasDoc(props.document.name !== "");
  }, [props.document.notes, props.document.name]);

  const toggleDocumentInfoDialog = () => {
    setDocumentInfoDialogOpen(!documentInfoDialogOpen);
  };

  const handleSubmit = async (values) => {
    toggleDocumentInfoDialog();
    await props.saveDocument(values);
  };

  const handleClearDocument = async (values) => {
    props.clearDocument(values);
    setDragState('');
  };

  const getTitle = () => {
    if (props.document.id !== 0) {
      if (hasNote && (!hasDoc && props.document.name === "")) {
        return "Note added without document";
      }
      return props.document.name;
    }
    return "Please upload document";
  };

  const getClasses = () => {
    if (props.document.id !== 0) {
      if (props.document.notes !== "" && props.document.name === "") {
        return `icon-info ${styles.info}`;
      } else {
        return "icon-tickcircle";
      }
    }
    return "icon-circle";
  };

  const isProofOfIdentityDoc = (type) => {
    switch (type) {
      case "ProofOfIdentity":
      case "SecondLevelControllingPersonProofOfIdentity":
      case "BeneficialOwnerProofOfIdentity":
        return true;
      default:
        return false;
    }
  };

  const handleDragEnter = (e) => {
    e.stopPropagation();
    setDragState('dragover');
    setDragCount(dragCount + 1);
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    if (dragCount <= 1) {
      setDragState('');
    }
    setDragCount(dragCount - 1);
  };

  const handleDrop = (e) => {
    setDragState('dropped');
    setDragCount(0);
  };

  const uploadDone = (e) => {
    setDragState('');
  };

  return (
    <div
      ref={props.refs || null}
      id={props.id}
      className={`${styles.container} ${props.document.name !== "" ? styles.active : null} ${styles[dragState]} ${props.classes}`}
      title={getTitle()}
      onDrop={(e) => handleDrop(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      <div className={`largeIcon ${styles.circle} ${getClasses()}`}>
        {/* <CircularProgress size={36} thickness={5.5} /> */}
      </div>
      <div>
        <span>{props.document.name}</span>
        <span className={styles.dropTitle}>{props.document.title}</span>
        <span className={styles.dropText}>Click or drop files here to upload</span>
        {
          props.openGuide ?
            <button className={`iconTextButton ${styles.notesButton}`} onClick={props.openGuide}>Guide</button>
            : null
        }

        <button className={`iconTextButton ${styles.notesButton}`} onClick={() => {
          setDocumentInfoDialogOpen(true);
        }}>
          {props.document.notes !== "" ? "View Note" : "Add Note"}
        </button>
        <button className={`iconTextButton ${styles.notesButton}`} disabled={props.document.id === 0} onClick={() => handleClearDocument(props.document)}>Clear</button>
        <UploadDocument
          document={props.document}
          uploadDocument={props.uploadDocument}
          uploadDone={uploadDone}
          setLoading={handleDrop}
        />
        <DocumentInfo
          isOpen={documentInfoDialogOpen}
          handleSubmit={handleSubmit}
          handleClose={toggleDocumentInfoDialog}
          document={props.document}
        />
      </div>
      <div className={`${styles.logo} ${isProofOfIdentityDoc(props.document.type) ? styles.idIcon : null} `}>
        {isProofOfIdentityDoc(props.document.type) ?
          <svg width="35" height="44" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="35" height="44" rx="3" fill="#3F3D56" />
            <path d="M3.5 3.5H24.5V24.5H3.5V3.5Z" fill="white" stroke="#DDDDDD" />
            <path d="M16.4066 18.7616V17.4641L14.1314 16.4487L11.8562 17.4641V18.7616L14.1314 19.4573L16.4066 18.7616Z" fill="#F9C58C" />
            <path d="M21.1266 21.8831C20.4873 21.0746 18.9266 20.7737 18.9266 20.7737C17.7608 20.3976 17.0086 19.9652 16.6514 19.7207C16.3129 20.0968 15.6172 20.8301 14.8463 21.1122C14.8463 21.1122 14.8087 20.3976 14.4514 19.7959L14.0941 19.6831L13.7369 19.7959C13.3796 20.3976 13.342 21.1122 13.342 21.1122C12.571 20.8301 11.8753 20.1156 11.5369 19.7207C11.1796 19.9652 10.4275 20.3976 9.26164 20.7737C9.26164 20.7737 7.70095 21.0746 7.06164 21.8831C7.06164 21.8831 6.59155 22.466 6.59155 23.425V24.0831H14.0753H21.5591V23.425C21.5967 22.466 21.1266 21.8831 21.1266 21.8831Z" fill="#15A186" />
            <path d="M17.7605 12.5564C17.7605 12.5564 18.381 8.90855 17.1024 8.00599C17.1024 8.00599 16.181 7.00941 14.1127 7.19744C12.0443 7.00941 11.1229 8.00599 11.1229 8.00599C9.86309 8.90855 10.4648 12.5564 10.4648 12.5564C9.67506 12.1427 9.71266 13.1017 9.71266 13.1017C9.63745 15.0009 10.8221 15.3205 10.8221 15.3205C11.3109 18.2915 14.0939 18.7615 14.0939 18.7615C14.0939 18.7615 16.8768 18.2915 17.3657 15.3205C17.3657 15.3205 18.5503 15.0009 18.475 13.1017C18.5127 13.1017 18.5691 12.1427 17.7605 12.5564Z" fill="#FDA57D" />
            <path d="M14.1316 5.5428C14.1316 5.5428 10.7469 5.5052 10.0324 8.2881C10.0324 8.2881 9.31788 11.2214 10.6717 12.9702C10.6717 12.9702 10.5401 9.20947 11.7999 8.62657C11.7999 8.62657 12.0444 10.977 14.5452 10.9958C14.5452 10.9958 13.9811 10.3753 13.8871 9.75477C13.8871 9.75477 14.9401 10.6761 16.1247 10.7514C16.1247 10.7514 15.7675 9.49152 15.9179 8.77699C15.9179 8.77699 17.817 9.924 17.6102 12.9514C17.6102 12.9514 19.6786 9.60434 17.629 6.76503C17.629 6.78383 16.7076 5.48639 14.1316 5.5428Z" fill="#333333" />
            <path d="M16.4068 18.7617L14.1315 19.4574L11.8563 18.7617L11.3862 19.4574C11.3862 19.4574 11.4615 19.5515 11.5931 19.7019C11.9315 20.078 12.6273 20.8113 13.3982 21.0933C13.3982 21.0933 13.4358 20.3788 13.7931 19.7771L14.1503 19.6643L14.5076 19.7771C14.8649 20.3788 14.9025 21.0933 14.9025 21.0933C15.6734 20.8113 16.3692 20.0968 16.7076 19.7019C16.8392 19.5515 16.9144 19.4574 16.9144 19.4574L16.4068 18.7617Z" fill="white" />
            <path d="M24.7036 31.6391H3.29636C3.13946 31.6391 3 31.5075 3 31.3195C3 31.1504 3.12203 31 3.29636 31H24.7036C24.8605 31 25 31.1316 25 31.3195C25 31.5075 24.8605 31.6391 24.7036 31.6391Z" fill="#DDDDDD" />
            <path d="M23.7306 29.6391H4.26942C4.12678 29.6391 4 29.5075 4 29.3195C4 29.1504 4.11094 29 4.26942 29H23.7306C23.8732 29 24 29.1316 24 29.3195C24 29.5075 23.8732 29.6391 23.7306 29.6391Z" fill="#DDDDDD" />
            <path d="M21.7845 27.6391H6.21553C6.10143 27.6391 6 27.5075 6 27.3195C6 27.1504 6.08875 27 6.21553 27H21.7845C21.8986 27 22 27.1316 22 27.3195C22 27.5075 21.8986 27.6391 21.7845 27.6391Z" fill="#DDDDDD" />
          </svg>
          :
          <svg width={55} height={33} viewBox="0 0 55 33" fill="none">
            <path
              className={styles.sun}
              d="M14.222 18.597c3.764 0 6.816-3.092 6.816-6.906 0-3.813-3.052-6.905-6.816-6.905s-6.816 3.092-6.816 6.905c0 3.814 3.052 6.906 6.816 6.906z"
              fill="#FF6584"
            />
            <path
              d="M35.62 33H2.14c-.043 0-.087 0-.131-.015l15.84-27.784c.307-.533.98-.726 1.506-.43.176.104.322.252.425.43l10.618 18.641.512.89L35.62 33z"
              fill="#3F3D56"
            />
            <path
              opacity={0.2}
              d="M35.62 33H24.183l5.616-8.254.395-.592.205-.312.512.904L35.62 33z"
              fill="#000"
            />
            <path
              d="M54.254 33h-28.96l5.616-8.254.41-.592 7.313-10.758c.483-.712 1.638-.756 2.194-.134.044.045.073.09.102.134L54.254 33z"
              fill="#3F3D56"
            />
          </svg>
        }
      </div>
    </div>
  );
};