import React, { Component } from 'react';
import * as Shared from '../../../../shared/sharedTypes'
import { GlobalService } from '../../../../services/globalService';
import Document from '../../../../shared/documentUpload/documentComponent';
import { SecondLevelControllingPersonService } from '../../../../services/secondLevelControllingPersonService';
import { SecondLevelControllingPersonDocumentService } from '../../../../services/secondLevelControllingPersonDocumentService';
import { ControllingPersonService } from '../../../../services/controllingPersonService'
import { ValidationService } from '../../../../services/validationService';
import MessageDialog from '../../../../shared/dialogs/dialogMessage';
import styles from "../../../../../assets/styles/notifications.module.scss";

const proofOfIdentityHighRiskTitle: string = "Proof of Identification (Certified)"
const proofOfIdentityLowRiskTitle: string = "Proof of Identification"
const proofOfAddressHighRiskTitle: string = "Proof of Address (Certified)"
const proofOfAddressLowRiskTitle: string = "Proof of Address"
const structureChartDocName: string = "Structure Chart (Optional)"
const structureChartGuid: string = "Where corporate intermediaries are present between a natural person (who ultimately exercises control over the controlling entity) and the controlling entity, please add a structure chart which demonstrates that connection"

const proofOfIdentityDoc: Shared.IDocument = {
  ...Shared.EmptyDocument,
  type: 'SecondLevelControllingPersonProofOfIdentity',
}
const proofOfAddressDoc: Shared.IDocument = {
  ...Shared.EmptyDocument,
  type: 'SecondLevelControllingPersonProofOfAddress'
}
const structureChartDoc: Shared.IDocument = {
  ...Shared.EmptyDocument,
  title: structureChartDocName,
  type: 'SecondLevelControllingPersonStructureChart'
}

class State {
  guideTitle: string = ""
  guideDescription: string = ""
  guideDialogOpen: boolean = false
  proofOfAddress: Shared.IDocument = proofOfAddressDoc
  proofOfIdentity: Shared.IDocument = proofOfIdentityDoc
  structureChart: Shared.IDocument = structureChartDoc
}

export class SecondLevelControllingPersonDocuments extends Component<Shared.IEntityProps> {
  state = new State();
  constructor(props: Shared.IEntityProps) {
    super(props)
    this.loadDocuments = this.loadDocuments.bind(this)
    this.setNextActiveStep = this.setNextActiveStep.bind(this)
    this.populateSecondLevelControllingPersonData = this.populateSecondLevelControllingPersonData.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
    this.clearDocument = this.clearDocument.bind(this);
    this.validateSecondLevelControllingPersonDocuments = this.validateSecondLevelControllingPersonDocuments.bind(this);
  }

  componentDidMount() {
    this.loadDocuments();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.secondLevelControllingPerson.documents !== this.props.secondLevelControllingPerson?.documents) {
      this.loadDocuments();
    }
  }

  async loadDocuments() {
    let highRiskInvestor: boolean = this.props.entityInvestor?.highRiskInvestor === true;
    var proofOfIdentityTitle = highRiskInvestor ? proofOfIdentityHighRiskTitle : proofOfIdentityLowRiskTitle;

    var document: Shared.IDocument | undefined = this.props.secondLevelControllingPerson!.documents.find(x => x.type === 'SecondLevelControllingPersonProofOfIdentity')
    if (document !== undefined) {
      document.title = proofOfIdentityTitle
      this.setState({ proofOfIdentity: document })
    } else {
      this.setState({ proofOfIdentity: { ...proofOfIdentityDoc, title: proofOfIdentityTitle } })
    }

    document = this.props.secondLevelControllingPerson!.documents.find(x => x.type === 'SecondLevelControllingPersonProofOfAddress')
    var addressTitle = highRiskInvestor ? proofOfAddressHighRiskTitle : proofOfAddressLowRiskTitle;
    if (document !== undefined) {
      document.title = addressTitle
      this.setState({ proofOfAddress: document })
    } else {
      this.setState({ proofOfAddress: { ...proofOfAddressDoc, title: addressTitle } })
    }

    document = this.props.secondLevelControllingPerson!.documents.find((x: { type: string; }) => x.type === 'SecondLevelControllingPersonStructureChart')
    if (document !== undefined) {
      document.title = structureChartDocName
      this.setState({ structureChart: document })
    } else {
      this.setState({ structureChart: structureChartDoc })
    }
  }

  toggleGuideDialogClose() {
    this.setState({
      guideTitle: "Structure Chart Guide",
      guideDescription: structureChartGuid,
      guideDialogOpen: !this.state.guideDialogOpen
    })
  }

  async saveDocument(values: Shared.IDocument) {
    let document: Shared.IDocument = {
      ...values,
      controllingPersonId: this.props.secondLevelControllingPerson!.controllingPersonId,
      secondLevelControllingPersonId: this.props.secondLevelControllingPerson!.id
    }
    await SecondLevelControllingPersonDocumentService.saveDocument(document)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.populateSecondLevelControllingPersonData(document.secondLevelControllingPersonId, document.controllingPersonId);
          await this.validateSecondLevelControllingPersonDocuments();
        }
      });
  }

  async uploadDocument(values: Shared.IDocument) {
    if (values.content.files[0] === undefined) {
      return
    }
    const formData = new FormData();
    formData.append('name', values.content.files[0].name);
    formData.append('type', values.type);
    formData.append('content', values.content.files[0]);
    formData.append('controllingPersonId', this.props.secondLevelControllingPerson!.controllingPersonId);
    formData.append('secondLevelControllingPersonId', this.props.secondLevelControllingPerson!.id);
    await SecondLevelControllingPersonDocumentService.uploadDocument(formData)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.populateSecondLevelControllingPersonData(this.props.secondLevelControllingPerson!.id, this.props.secondLevelControllingPerson!.controllingPersonId);
          await this.validateSecondLevelControllingPersonDocuments();
        }
      });
  }

  async clearDocument(values: Shared.IDocument) {
    await SecondLevelControllingPersonDocumentService.clearDocument(values.id, this.props.secondLevelControllingPerson!.id, this.props.secondLevelControllingPerson!.controllingPersonId)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
          await this.populateSecondLevelControllingPersonData(this.props.secondLevelControllingPerson!.id, this.props.secondLevelControllingPerson!.controllingPersonId);
          await this.validateSecondLevelControllingPersonDocuments();
        }
      });
  }

  async populateSecondLevelControllingPersonData(id: string, controllingPersonId: string) {
    await SecondLevelControllingPersonService.getSecondLevelControllingPerson(id, controllingPersonId)
      .then(async result => {
        await this.props.updateSecondLevelControllingPerson!(result);
        await this.loadDocuments();
      })
  };

  async setNextActiveStep() {
    await this.validateSecondLevelControllingPersonDocuments();
    this.props.handleClose!();
  }

  async validateSecondLevelControllingPersonDocuments() {
    await ValidationService.validateSecondLevelControllingPerson(
      this.props.secondLevelControllingPerson!.controllingPersonId,
      this.props.secondLevelControllingPerson!.id,
      "Documents")
      .then(result => {
        GlobalService.sendNotification(result)
      });
  }

  render() {
    return (
      <>
        {this.props.bindGetNextStepHandler!(this.setNextActiveStep)}
        <div className="stepContent">
          <h1>Identification for {ControllingPersonService.getControllingPersonType(this.props.controllingPerson!.type)}</h1>
          <div className={`stepLeft`}>
            <div className={`overflowText`}>
              <div>
                <h3 className="stepCount">{`Step ${this.props.activeStep}`}</h3>
                <h2>Upload Documents</h2>
              </div>
              <article>
                <p>Maples Compliance Services (Cayman) Limited have been engaged to identify investors in line with the Cayman Islands Know Your Customer (“KYC”) standards.<br /><br /> Please provide proof of Identification and Proof of address in the fields provided.</p>
              </article>
            </div>
          </div>
          <div className={`stepRight stepDetails`}>
            <div className="rowWrap">
              <Document
                document={this.state.proofOfIdentity}
                uploadDocument={this.uploadDocument}
                saveDocument={this.saveDocument}
							clearDocument={this.clearDocument}
							classes={`${(this.props.activeNotification?.notificationKey === this.state.proofOfIdentity.type && this.state.proofOfIdentity.name === "") ? styles.hasNotification : null}`}
						/>
              <Document
                document={this.state.proofOfAddress}
                uploadDocument={this.uploadDocument}
                saveDocument={this.saveDocument}
							clearDocument={this.clearDocument}
							classes={`${(this.props.activeNotification?.notificationKey === this.state.proofOfAddress.type && this.state.proofOfAddress.name === "") ? styles.hasNotification : null}`}
						/>
              <Document
                document={this.state.structureChart}
                uploadDocument={this.uploadDocument}
                saveDocument={this.saveDocument}
                clearDocument={this.clearDocument}
							openGuide={() => { this.toggleGuideDialogClose() }}
							classes={`${(this.props.activeNotification?.notificationKey === this.state.structureChart.type && this.state.structureChart.name === "") ? styles.hasNotification : null}`}
						/>
            </div>
          </div>
        </div>
        <MessageDialog
          isOpen={this.state.guideDialogOpen}
          handleClose={() => { this.setState({ guideDialogOpen: !this.state.guideDialogOpen }) }}
          title={this.state.guideTitle}
          description={this.state.guideDescription}
          cancelText="Cancel"
          confirmText="Delete"
        />
      </>
    );
  }
}