import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox';
import * as Shared from '../shared/sharedTypes'
import styles from './dataProtection.module.scss';
import { InvestorService } from '../services/investorService'
import { ValidationService } from '../services/validationService';
import { GlobalService } from '../services/globalService';

export class DataProtection extends Component<Shared.IProps, Shared.IInvestorState>{
  constructor(props: Shared.IProps) {
    super(props);
    this.state = {
      investor: (this.props.investor !== undefined) ? this.props.investor : Shared.EmptyInvestor,
      isChecked: this.props.investor ? this.props.investor.dataProtectionAccepted : false
    };
	  this.handleSubmit = this.handleSubmit.bind(this);
	  this.setNextActiveStep = this.setNextActiveStep.bind(this)
  }

	async setNextActiveStep() {
		if (!this.state.isChecked) {
			GlobalService.sendSnack('error', 'Accepting of Data Protection is mandatory');
		}
		else {
			this.props.setActiveStep!(1);
		}
	}

	async handleSubmit(values: Shared.IInvestor) {
		if (values.dataProtectionAccepted) {
      values.dataProtectionAcceptanceDate = new Date();
			await InvestorService.update(values);
			await this.props.updateInvestor(values);
			await ValidationService.validateInvestor('DataProtection')
				.then(result => {
					this.props.setCaseIsValid && this.props.setCaseIsValid(result.totalIssues === 0)
					GlobalService.sendNotification(result)
				})
		}
	}

  handleChange(event) {
    this.setState({
      isChecked: !this.state.isChecked
    })
  }

  public render() {
    return (
      <div className={'stepContent ' + styles.dataProtectionContainer}>
        <h1>Data Protection</h1>
        <div className={`stepDetails stepRight ` + styles.stepDetails1col}>
          <div>
            <h3 className="stepCount">{`Step ${this.props.currentStep}`}</h3>
            <h2>Data protection policy</h2>

            <Formik enableReinitialize initialValues={this.state.investor} onSubmit={this.handleSubmit}>
              {
                (formikProps) => {
					this.props.bindSubmitFormHandler!(formikProps.submitForm);
					this.props.bindGetNextStepHandler!(this.setNextActiveStep);
                  this.props.setFormikProps!(formikProps);
                  return (
                    <>
                      <article>
                        <p><strong>Maples Compliance Services (Cayman) Limited (“MCSCL”)</strong> will, in its capacity as a delegate acting on behalf of the fund, use the information you provide in response to this KYC request (including any documentation you provide) to perform due diligence which the fund is by law required to perform, and to determine if the fund is in a position to accept your subscription. A detailed explanation regarding why and how MCSCL handles personal information in general is available in the form of a separate Client Privacy Notice, which is available upon request or can be accessed online at <a href="https://maples.com/privacy" target="_blank" rel="noopener noreferrer">https://maples.com/privacy</a>. When you respond to this KYC request, MCSCL will deem that any person whose personal information is included in your response to this KYC request (or any additional documentation you provide) have read and understood the Client Privacy Notice applicable to MCSCL.</p>
                      </article>
                      <Form className="rowWrap">
                        <FormControlLabel
                          className={`${styles.checkBox} ${this.state.isChecked ? styles.isChecked : ""}`}
                          value="dataProtectionAccepted"
                          label={<Typography className={styles.checkBoxLabel}>I acknowledge that I have read and understand the materials above</Typography>}
                          labelPlacement="end"
                          control={
                            <Field name="dataProtectionAccepted">
                              {({
                                field
                              }) => (
                                  <Checkbox color="primary" name="dataProtectionAccepted" onClick={(e) => this.handleChange(e)} checked={!!field.value} {...field} />
                                )}
                            </Field>
                          }
                        />
                      </Form>
                    </>
                  )
                }
              }
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}